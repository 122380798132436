import get from 'lodash.get'

export const ShirtDetailsForm = {
  title: 'Shirt details',
  display: 'Shirt',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        // {
        //   label: 'Price',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['price'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Collection',
          type: 'basicRadio',
          isRequired: true,
          key: ['collection'],
          options: [
            { key: 'af_collections', label: 'Collections' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            // row: 5,
            size: 2,
          },
        },
        {
          label: 'Fabric ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          key: ['fabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}collection`, '') !== 'af_collections',
          options: [
            { key: 'rmc22g183', label: 'Bamboo White (RMC22G183)' },
            { key: 'hw31510', label: 'White classic (HW31510)' },
            { key: 'hg1825_1', label: 'White LT (HG1825-1)' },
            { key: 'hg1825_2', label: 'Blue LT (HG1825-2)' },
            { key: 'hw31520', label: 'Blue chiné (HW31520)' },
            { key: 'black', label: 'Black (BLACK)' },
            { key: 'hg1830_2', label: 'Baby blue LP (HG1830-2)' },
            { key: 'hg1830_1', label: 'Purple LP (HG1830-1)' },
            { key: 'hw35027', label: 'Stripes WB (HW35027)' },
            { key: 'r11040', label: 'Pink (R11040)' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Other Fabric ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherFabricRef'],
          isHiding: (values, name) =>
            get(values, `${name}collection`, '') === 'af_collections' &&
            get(values, `${name}fabricRef`, '') !== 'others',
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Collar style',
          type: 'select',
          key: ['collarStyle'],
          options: [
            { key: 'classic_reqcla', label: 'Classic REQCLA' },
            { key: 'italian_reqclx', label: 'Italian REQCLX' },
            { key: 'italian_spread_reqcl24', label: 'Italian spread REQCL24' },
            { key: 'band_roll_reqcl23', label: 'Band roll REQCL23' },
            { key: 'american_reqclf', label: 'American REQCLF' },
            { key: 'tuxedo_reqclk', label: 'Tuxedo REQCLK' },
            { key: 'club_reqclo', label: 'Club REQCLO' },
            { key: 'mao_reqclh', label: 'Mao REQCLH' },
            { key: 'bar_collar_reqcl19', label: 'Bar collar REQCL19' },
            { key: 'other', label: 'Others' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            // row: 3,
            size: 1,
          },
        },
        {
          label: 'Other Collar style ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherCollarStyle'],
          isHiding: (values, name) =>
            get(values, `${name}collarStyle`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Cuff style',
          type: 'select',
          key: ['cuffStyle'],
          options: [
            {
              key: '1_button_hole_square_style',
              label: '1 button hole square style',
            },
            {
              key: '1_button_hole_hexagon_style',
              label: '1 button hole hexagon style',
            },
            {
              key: '1_button_hole_rounded_style',
              label: '1 button hole rounded style',
            },
            {
              key: '2_button_hole_square_style',
              label: '2 button hole square style',
            },
            {
              key: '2_button_hole_hexagon_style',
              label: '2 button hole hexagon style',
            },
            {
              key: '2_button_hole_rounded_style',
              label: '2 button hole rounded style',
            },
            { key: 'french_cuff_square', label: 'French cuff square' },
            { key: 'french_cuff_hexagon', label: 'French cuff hexagon' },
            { key: 'french_cuff_rounded', label: 'French cuff rounded' },
            {
              key: '1_button_hole_rounded_add_extra_cuff_links',
              label: '1 button hole rounded + 1 extra for cuff links',
            },
            { key: 'short_sleeve_plain', label: 'Short sleeve plain' },
            {
              key: 'short_sleeve_with_inner_tap',
              label: 'Short sleeve with inner tap',
            },
            { key: 'other', label: 'Others' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            // row: 4,
            size: 1,
          },
        },
        {
          label: 'Other Cuff style ref',
          isRequired: true,
          placeholder: '-',
          key: ['otherCuffStyle'],
          isHiding: (values, name) =>
            get(values, `${name}cuffStyle`, '') !== 'other',
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Pocket',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['pocket'],
          options: [
            {
              key: 'no',
              label: 'No',
            },
            {
              key: 'left',
              label: 'Left side',
            },
            {
              key: 'right',
              label: 'Right side',
            },
          ],
          pos: {
            col: 1,
            // row: 5,
            size: 2,
          },
        },
        {
          label: 'Pocket options',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          isHiding: (values, name) => get(values, `${name}pocket`, '') === 'no',
          key: ['pocketOptions'],
          options: [
            {
              key: 'triangle',
              label: 'Triangle',
            },
            {
              key: 'square',
              label: 'Square',
            },
            {
              key: 'button_rounded_with_flap',
              label: 'Button rounded with flap',
            },
          ],
          pos: {
            col: 1,
            // row: 6,
            size: 2,
          },
        },
        {
          label: 'Placket',
          type: 'basicRadio',
          isRequired: true,
          key: ['placket'],
          options: [
            {
              key: 'no',
              label: 'No (plain packet)',
            },
            {
              key: 'visible_placket',
              label: 'Visible placket',
            },
            {
              key: 'hidden_button',
              label: 'Hidden button',
            },
            {
              key: 'pleated_tuxedo',
              label: 'Pleated tuxedo',
            },
          ],
          pos: {
            col: 1,
            // row: 7,
            size: 2,
          },
        },
        {
          label: 'Add your monogram',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['addMonogram'],
          options: [
            {
              key: 'af_logo',
              label: 'logo',
            },
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            // row: 8,
            size: 2,
          },
        },
        {
          label: 'Monogram text',
          isRequired: true,
          placeholder: '-',
          key: ['monogramText'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') !== 'yes',
          pos: {
            col: 1,
            // row: 9,
            size: 1,
          },
        },
        {
          label: 'Monogram font',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['monogramFont'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') !== 'yes',
          options: [
            {
              key: 'lucida_handwritting_6mm',
              label: 'Lucida handwriting 6mm',
            },
            {
              key: 'garamond_6mm',
              label: 'Garamond 6mm',
            },
            {
              key: 'monotype_corsiva_6mm',
              label: 'Monotype Corsiva 6mm',
            },
            {
              key: 'chinese_typo',
              label: 'Chinese typo',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Monogram color',
          type: 'select',
          key: ['monogramColor'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') !== 'yes',
          options: [
            { key: 'dark_blue_799', label: 'Dark blue (799)' },
            { key: 'white_1', label: 'White (1)' },
            { key: 'gold_120', label: 'Gold (120)' },
            { key: 'light_blue_334', label: 'Light blue (334)' },
            { key: 'red_36', label: 'Red (36)' },
            { key: 'black_522', label: 'Black (522)' },
          ],
          isRequired: true,
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Monogram position',
          type: 'select',
          key: ['monogramPosition'],
          isHiding: (values, name) =>
            get(values, `${name}addMonogram`, '') !== 'yes',
          options: [
            { key: 'bottom_left_reqloi', label: 'Bottom left REQLOI' },
            { key: 'bottom_right_regloj', label: 'Bottom right REGLOJ' },
            { key: 'liver_left_reqlog', label: 'Liver left REQLOG' },
            { key: 'liver_right_reqloh', label: 'Liver right REQLOH' },
            { key: 'cuff_left_reqloc', label: 'Cuff left REQLOC' },
            { key: 'cuff_right_reqlod', label: 'CUff right REQLOD' },
            { key: 'pocket', label: 'Pocket' },
          ],
          isRequired: true,
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
        {
          label: 'Add contrast part',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['addContrastPart'],
          options: [
            {
              key: 'yes',
              label: 'Yes',
            },
            {
              key: 'no',
              label: 'No',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast position',
          type: 'multiSelect',
          isHiding: (values, name) =>
            get(values, `${name}addContrastPart`, '') !== 'yes',
          key: ['contrastPositionMulti'],
          isRequired: true,
          options: [
            {
              key: 'inner_top_collar',
              label: 'Inner top collar',
            },
            {
              key: 'outside_top_collar',
              label: 'Outside top collar',
            },

            {
              key: 'inner_collar_band',
              label: 'Inner collar band',
            },
            {
              key: 'cuff_outside',
              label: 'Cuff outside',
            },
            {
              key: 'cuff_inside',
              label: 'Cuff inside',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Contrast fabric',
          isHiding: (values, name) =>
            get(values, `${name}addContrastPart`, '') !== 'yes',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          key: ['contrastFabric'],
          options: [
            {
              key: 'white_rmc90054',
              label: 'White RMC90054',
            },
            {
              key: 'other',
              label: 'Other fabric',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Other options',
          isHiding: (value, name) =>
            get(value, `${name}contrastFabric`, '') !== 'other',
          key: ['otherOptions'],
        },
        {
          label: 'Fit preferences',
          type: 'basicRadio',
          default: 'no',
          isRequired: true,
          foldable: true,
          key: ['fitPreference'],
          options: [
            {
              key: 'slim_fit',
              label: 'Slim fit',
            },
            {
              key: 'standard_fit',
              label: 'Standard',
            },
            {
              key: 'loose_fit',
              label: 'Loose',
            },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: 'Comment',
          type: 'text',
          key: ['comment'],
          foldable: true,
          pos: {
            col: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const AddShirtsForm = {
  title: 'Add more shirts',
  key: [],
  sections: [
    {
      columns: 2,
      key: ['extraShirts'],
      canAdd: true,
      display: 'Extra Shirt',
      fields: [
        // {
        //   label: 'Add other shirts with the same personalization? (number)',
        //   // isRequired: true,
        //   type: 'number',
        //   placeholder: '-',
        //   key: ['addMore'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        {
          label: 'Price',
          type: 'select',
          isRequired: true,
          key: ['price'],
          onChangeRef: ['priceNum'],
          default: 'Select a price',
          placeholder: 'Select a price',
          onChange: (value) => {
            const selectedPrice =
              window.shirtPricing?.find((p) => p.key === value)?.price || 0
            return selectedPrice
          },
          options: [
            { key: 'classic', label: 'Classic' },
            { key: 'premium', label: 'Premium' },
            { key: 'master', label: 'Master' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        {
          label: '',
          type: 'number',
          isInactive: true,
          key: ['priceNum'],
          isRequired: true,
          placeholder: '-',
          pos: {
            col: 1,
            size: 2,
          },
        },

        {
          label: 'Collection',
          type: 'basicRadio',
          isRequired: true,
          key: ['collectionAddMore'],
          default: 'af_collections',
          options: [
            { key: 'af_collections', label: 'Collections' },
            { key: 'others', label: 'Others' },
          ],
          pos: {
            col: 1,
            size: 2,
          },
        },
        // {
        //   label: 'Fabric ref',
        //   type: 'select',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['fabricRefAddMore'],
        //   isHiding: (values, name) =>
        //     !get(values, `${name}addMore`, '') ||
        //     get(values, `${name}collectionAddMore`, '') !== 'af_collections',
        //   options: [
        //     { key: 'hw31510', label: 'White classic (HW31510)' },
        //     { key: 'hg1825_1', label: 'White LT (HG1825-1)' },
        //     { key: 'hg1825_2', label: 'Blue LT (HG1825-2)' },
        //     { key: 'hw31520', label: 'Blue chiné (HW31520)' },
        //     { key: 'black', label: 'Black (BLACK)' },
        //     { key: 'hg1830_1', label: 'Baby blue LP (HG1830-1)' },
        //     { key: 'hg1830_2', label: 'Purple LP (HG1830-2)' },
        //     { key: 'hw35027', label: 'Stripes WB (HW35027)' },
        //     { key: 'r11040', label: 'Pink (R11040)' },
        //     { key: 'others', label: 'Others' },
        //   ],
        //   pos: {
        //     col: 1,
        //     // row: 2,
        //     size: 1,
        //   },
        // },

        // {
        //   label: 'Fabric ref (If more than one, separate by "/")',
        //   isConditionalRequired: (values, name) =>
        //     !!get(values, `${name}addMore`, false),
        //   // isRequired: true,
        //   placeholder: '-',
        //   key: ['otherFabricRef'],
        //   pos: {
        //     col: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Add Fabric ref',
        //   type: 'addRef',
        //   isRequired: true,
        //   options: [
        //     { key: 'hw31510', label: 'White classic (HW31510)' },
        //     { key: 'hg1825_1', label: 'White LT (HG1825-1)' },
        //     { key: 'hg1825_2', label: 'Blue LT (HG1825-2)' },
        //     { key: 'hw31520', label: 'Blue chiné (HW31520)' },
        //     { key: 'black', label: 'Black (BLACK)' },
        //     { key: 'hg1830_1', label: 'Baby blue LP (HG1830-1)' },
        //     { key: 'hg1830_2', label: 'Purple LP (HG1830-2)' },
        //     { key: 'hw35027', label: 'Stripes WB (HW35027)' },
        //     { key: 'r11040', label: 'Pink (R11040)' },
        //   ],
        //   isHiding: (values, name) => !get(values, `${name}addMore`, ''),
        //   isNoValid: (values, name) => {
        //     const nb = get(values, `${name}addMore`, [])

        //     if (!nb || nb === get(values, `${name}addMoreRef`, []).length) {
        //       return false
        //     }
        //     return 'Number of shirt ref not matching'
        //   },
        //   key: ['addMoreRef'],
        //   pos: {
        //     col: 1,
        //     // row: 2,
        //     size: 1,
        //   },
        // },
        {
          label: 'Add Fabric ref',
          type: 'select',
          isRequired: true,
          placeholder: '-',
          options: [
            { key: 'hw31510', label: 'White classic (HW31510)' },
            { key: 'hg1825_1', label: 'White LT (HG1825-1)' },
            { key: 'hg1825_2', label: 'Blue LT (HG1825-2)' },
            { key: 'hw31520', label: 'Blue chiné (HW31520)' },
            { key: 'black', label: 'Black (BLACK)' },
            { key: 'hg1830_1', label: 'Baby blue LP (HG1830-1)' },
            { key: 'hg1830_2', label: 'Purple LP (HG1830-2)' },
            { key: 'hw35027', label: 'Stripes WB (HW35027)' },
            { key: 'r11040', label: 'Pink (R11040)' },
            { key: 'rmc22g183', label: 'Bamboo White (RMC22G183)' },
            { key: 'others', label: 'Others' },
          ],
          isHiding: (values, name) => {
            return (
              get(values, `${name}collectionAddMore`, '') !== 'af_collections'
            )
          },
          key: ['addMoreRef'],
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
        {
          label: 'Other Fabric ref',
          placeholder: '-',
          key: ['otherFabricRefAddMore'],
          isHiding: (values, name) =>
            get(values, `${name}collectionAddMore`, '') !== 'others',
          pos: {
            col: 1,
            // row: 2,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const ShirtMeasureForm = {
  title: 'Final measurement Shirt',
  display: 'Shirt',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Shirt length front',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lengthFront'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Shirt length back',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lengthBack'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Shirt sleeve length',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['sleeveLength'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Neck',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['neck'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Shoulder',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['shoulder'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label:
            'Chest (The difference should be less than 18 cm with the field waist)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['chest'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Waist',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['waist'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Stomach',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['stomach'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Lower herm',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['lowerHerm'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Arm width (biceps)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['biceps'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },

        {
          label: 'Arm hole',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['armHole'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Shirt sleeve wrist (right)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['sleeveWristRight'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Shirt sleeve wrist (left)',
          type: 'number',
          isRequired: true,
          placeholder: '-',
          key: ['sleeveWristLeft'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        // {
        //   label: 'Right cuff',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['rightCuff'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
        // {
        //   label: 'Left cuff',
        //   type: 'number',
        //   isRequired: true,
        //   placeholder: '-',
        //   key: ['leftCuff'],
        //   pos: {
        //     col: 1,
        //     // row: 1,
        //     size: 1,
        //   },
        // },
      ],
    },
  ],
}
