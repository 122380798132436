import { Form, PageHeader } from 'antd'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import { createStore } from '../../network/Stores'
import {
  StoresDescriptionForm,
  StoresMediaForm,
  StoresFranchises,
  StoresFaviconForm,
} from '../../forms/Stores'
import { formOptions } from '../../forms/blocks'
import { useState, useEffect } from 'react'

export default function CreateStore({ history }) {
  const [form] = Form.useForm()
  const [isFranchise, setIsFranchise] = useState(false)

  // Get the list of products hardcoded
  const products = Object.keys(formOptions)
    .filter((key) => key !== 'collection') // Remove collection for the franchise
    .map((key) => ({
      key: key,
      name: formOptions[key].name,
      display: true,
    }))

  // Add a default product list for the franchise
  const handleSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        products,
      }
      await createStore(payload)
      history.push('/stores')
    } catch (error) {
      console.error('Erreur lors de la création du magasin:', error)
    }
  }

  const handleValuesChange = (changedValues) => {
    if (changedValues.franchise !== undefined) {
      setIsFranchise(changedValues.franchise)
    }
  }

  useEffect(() => {
    const initialFranchiseValue = form.getFieldValue('franchise')
    setIsFranchise(initialFranchiseValue)
  }, [form])

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Create store"
        onBack={() => history.push('/stores')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        form={form}
        title="create_store"
        request={handleSubmit}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/stores')}
        onCancel={() => history.push('/stores')}
        invalidate={['getUsers']}
        onValuesChange={handleValuesChange}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={StoresDescriptionForm} />
          {isFranchise && (
            <>
              <FormBlock form={form} formTemplate={StoresMediaForm} />
              <FormBlock form={form} formTemplate={StoresFaviconForm} />
            </>
          )}
        </div>
      </FormWrap>
    </>
  )
}
