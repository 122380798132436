import { Button, PageHeader } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import TableBlock from '../../components/tables/TableBlock'
import Status from '../../components/tables/cells/Status'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'
import { getOrders, updateOrder } from '../../network/Orders'
import { getCurrencySymbol } from '../../utils/currencyUtils'

const Orders = () => {
  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []
  const storeOptions = user.stores.map((store) => ({
    label: store.name,
    key: store.store,
  }))
  const [totalDueCurrency, setTotalDueCurrency] = useState('')

  let statusConfig = [
    { label: 'In preparation', key: 'in_preparation' },
    { label: 'In production', key: 'in_production' },
    { label: 'Producing', key: 'producing' },
    { label: 'Cancelled', key: 'cancelled' },
    { label: 'Ready to pick up', key: 'ready_to_pick_up' },
    { label: 'On the way (Client)', key: 'on_the_way_client' },
    { label: 'On the way (Store)', key: 'on_the_way_store' },
    { label: 'Order finished', key: 'order_finished' },
    { label: 'Missing Measurements', key: 'missing_measurement' },
  ]
  if (permissions.includes('follow_up_store_assistant')) {
    statusConfig = [
      { label: 'In preparation', key: 'in_preparation' },
      { label: 'Ready to pick up', key: 'ready_to_pick_up' },
      { label: 'On the way (Client)', key: 'on_the_way_client' },
      { label: 'On the way (Store)', key: 'on_the_way_store' },
      { label: 'Order finished', key: 'order_finished' },
    ]
  }
  if (permissions.includes('follow_up_production')) {
    statusConfig = [
      { label: 'In preparation', key: 'in_preparation' },
      { label: 'In production', key: 'in_production' },
      { label: 'Producing', key: 'producing' },
      { label: 'Cancelled', key: 'cancelled' },
      { label: 'On the way (Client)', key: 'on_the_way_client' },
      { label: 'On the way (Store)', key: 'on_the_way_store' },
    ]
  }

  const filters = (history) => ({
    fields: [
      {
        type: 'text',
        label: 'Customer name',
        key: 'customer.name',
        noRegex: true,
      },
      {
        type: 'text',
        label: 'Order ID',
        key: 'orderID',
      },
      {
        type: 'daterange',
        label: 'Dates',
        key: ['dateStart', 'dateEnd'],
      },
      {
        type: 'select',
        label: 'Store',
        key: 'store',
        options: storeOptions,
      },
      {
        type: 'select',
        label: 'Status',
        key: 'status',
        options: [
          { label: 'In preparation', key: 'in_preparation' },
          { label: 'In production', key: 'in_production' },
          { label: 'Producing', key: 'producing' },
          { label: 'Cancelled', key: 'cancelled' },
          { label: 'Ready to pick up', key: 'ready_to_pick_up' },
          { label: 'On the way (Client)', key: 'on_the_way_client' },
          { label: 'On the way (Store)', key: 'on_the_way_store' },
          { label: 'Order finished', key: 'order_finished' },
        ],
      },
      {
        type: 'select',
        label: 'Order Type',
        key: 'order.type',
        options: [
          { label: 'Product', key: 'product' },
          { label: 'Adjustment', key: 'adjustment' },
        ],
      },
    ],
    actions: [
      {
        label: '+ New order',
        isActive: permissions.includes('new_orders'),
        action: () => history.push('/orders/create'),
      },
    ],
  })

  const columns = (history, keyRequest) => [
    {
      title: 'Order ID',
      key: 'orderID',
      render: (e) => <div className={e.status}>{e.orderID}</div>,
    },
    {
      title: 'Customer name',
      key: 'customer.name',
      render: (e) => (
        <div
          className={e.status}
        >{`${e.customer?.firstName} ${e.customer?.lastName}`}</div>
      ),
      sorter: true,
    },
    {
      title: 'Date',
      key: 'date',
      render: (e) => (
        <div className={e.status}>{moment(e.date).format('YYYY-MM-DD')}</div>
      ),
      sorter: true,
    },
    {
      title: 'Status',
      key: 'status',
      width: 200,
      render: (e) => (
        <div className={e.status}>
          <Status
            status={e.status}
            fieldKey={'status'}
            requestName={'getOrders'}
            keyRequest={keyRequest}
            editRequest={updateOrder}
            noPoint
            id={e.id}
            config={statusConfig}
          />
        </div>
      ),
      sorter: true,
    },
    {
      title: 'Paid',
      key: 'paymentStatus',
      width: 175,
      render: (e) => (
        <div className={e.status}>
          <Status
            status={e.paymentStatus}
            fieldKey={'paymentStatus'}
            requestName={'getOrders'}
            keyRequest={keyRequest}
            editRequest={updateOrder}
            id={e.id}
            config={[
              { label: 'Unpaid', key: 'unpaid', color: 'red' },
              { label: 'Deposit', key: 'deposit', color: 'grey' },
              { label: 'Paid', key: 'paid', color: 'green' },
            ]}
          />

          {/* {e.paymentStatus} */}
        </div>
      ),
      sorter: true,
    },
    {
      title: 'Deposit',
      key: 'deposit',
      render: (e) => (
        <div className={e.status}>{`${e.deposit || 0} + ${
          e.deposit2 || 0
        }`}</div>
      ),
      sorter: true,
    },
    {
      title: 'Balance',
      key: 'balance',
      render: (e) => (
        <div className={e.status}>{e.balance?.toFixed(2) || 0}</div>
      ),
      sorter: true,
    },
    {
      title: `Total due (${totalDueCurrency})`,
      key: 'totalSpent',
      render: (e) => {
        let symbol = ''
        if (user.franchise === false) {
          symbol = getCurrencySymbol(e.currency)
        }

        if (user.franchiseStore) {
          const currencyTotalDue = {
            cny: 'RMB',
            eur: 'EUR',
            usd: 'USD',
            gbp: 'GBP',
            jpy: 'JPY',
            aud: 'AUD',
            cad: 'CAD',
            chf: 'CHF',
            hkd: 'HKD',
            nzd: 'NZD',
          }
          setTotalDueCurrency(currencyTotalDue[e.currency] || '')
        }

        return (
          <div className={e.status}>
            {`${(e.totalSpent - (e.discount?.toFixed(2) || 0)).toFixed(
              2
            )} ${symbol}`}
          </div>
        )
      },
      sorter: true,
    },

    {
      title: 'Store',
      key: 'store',
      render: (e) => (
        <div className={e.status}>{`${e.store?.name.en || 'Unknow'} 
        `}</div>
      ),
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: 25,
      render: (e) => {
        return (
          <div className={e.status}>
            <Button
              disabled={!permissions.includes('edit_orders')}
              onClick={() =>
                history.push(`/orders/${e.id}/store/${e.store.id}`)
              }
              type="primary"
            >
              Edit
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader className="site-page-header" title="Client follow up" />
      <TableBlock
        className={'order-table'}
        columns={columns}
        filters={filters}
        name={'getOrders'}
        request={getOrders}
        pageSize={100}
        otherFilters={
          user.franchiseStore || !permissions.includes('can-see-franchises')
            ? { store: user.currentStore }
            : {}
        }
        // infinite
      />
    </>
  )
}

export default Orders
