import { Form, PageHeader } from 'antd'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import FormBlock from '../../components/forms/FormBlock'
import FormWrap from '../../components/forms/FormWrap'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import {
  StoresDescriptionForm,
  StoresMediaForm,
  StoresFranchises,
  StoresDescriptionFranchise,
  StoresFaviconForm,
} from '../../forms/Stores'
import { getStoreDetails, updateStore } from '../../network/Stores'
import { useAuth } from '../../hooks/useAuth'

export default function EditStore({ history }) {
  const [form] = Form.useForm()
  let { storeId } = useParams()
  const { user, refreshStore, updateFavicon } = useAuth()
  const storeIsFranchise = user.franchiseStore
  const [isFranchise, setIsFranchise] = useState(false)
  const storeDetails = useQuery(
    ['getStoreDetails', { id: storeId }],
    getStoreDetails
  )

  const handleValuesChange = (changedValues) => {
    if (changedValues.franchise !== undefined) {
      setIsFranchise(changedValues.franchise)
    }
  }

  useEffect(() => {
    if (storeDetails.data) {
      const initialFranchiseValue = storeDetails.data.franchise
      setIsFranchise(initialFranchiseValue)
    }
  }, [storeDetails.data])

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit store'}
        onBack={() => history.push('/stores')}
        style={{ backgroundColor: '#fff' }}
      />
      {storeDetails.isError ? (
        <Error retry={() => storeDetails.refetch()} />
      ) : storeDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={storeId}
          form={form}
          title="update_store"
          request={updateStore}
          cancelText={'Cancel'}
          okText={'Save'}
          data={storeDetails.data}
          onSuccess={() => {
            history.push('/stores')
            refreshStore()
            updateFavicon()
          }}
          onCancel={() => history.push('/stores')}
          onValuesChange={handleValuesChange}
          cleanBeforeSending={(oldData) => {
            if (oldData.logo) {
              let main = oldData.logo.fileList
                ? oldData.logo?.fileList
                : oldData.logo

              if (!Array.isArray(main)) {
                main = [main]
              }
              const newPic = main.map((file) =>
                file.response ? file.response : file
              )

              oldData.logo = newPic.length ? newPic[0] : {}
            } else {
              oldData.logo = {}
            }
            return oldData
          }}
        >
          <div className="section-from-vertical">
            {storeIsFranchise ? (
              <>
                <FormBlock
                  form={form}
                  formTemplate={StoresDescriptionFranchise}
                />
                <FormBlock form={form} formTemplate={StoresMediaForm} />
                <FormBlock form={form} formTemplate={StoresFaviconForm} />
              </>
            ) : (
              <>
                <FormBlock form={form} formTemplate={StoresDescriptionForm} />
                {isFranchise && !storeIsFranchise && (
                  <>
                    <FormBlock form={form} formTemplate={StoresMediaForm} />
                    <FormBlock form={form} formTemplate={StoresFaviconForm} />
                  </>
                )}
              </>
            )}
          </div>
        </FormWrap>
      )}
    </>
  )
}
