import { Layout, Select, notification, Modal } from 'antd'
import { Switch, Route } from 'react-router-dom'
import { LogoutOutlined } from '@ant-design/icons'
import NavigationDrawer from './NavigationDrawer'

import Home from '../../pages/Home'
import Orders from '../../pages/Orders/Orders'
import EditOrder from '../../pages/Orders/EditOrder'
import ChooseTypeOrder from '../../pages/Orders/ChooseTypeOrder'
import CreateOrder from '../../pages/Orders/CreateOrder'
import ModifyOrder from '../../pages/Orders/ModifyOrder'
import CreateAdjustment from '../../pages/Orders/CreateAdjustment'
import Clients from '../../pages/Clients/Clients'
import CreateClient from '../../pages/Clients/CreateClient'
import EditClient from '../../pages/Clients/EditClient'
import ViewClient from '../../pages/Clients/ViewClient'
import Products from '../../pages/Products/Products'
import CreateProduct from '../../pages/Products/CreateProduct'
import EditProduct from '../../pages/Products/EditProduct'
import Categories from '../../pages/Categories/Categories'
import CreateCategory from '../../pages/Categories/CreateCategory'
import EditCategories from '../../pages/Categories/EditCategories'

import Members from '../../pages/Members/Members'
import CreateMember from '../../pages/Members/CreateMember'
import EditMember from '../../pages/Members/EditMember'
import Metrics from '../../pages/Metrics'

import Companies from '../../pages/Companies/Companies'
import CreateCompany from '../../pages/Companies/CreateCompany'
import EditCompany from '../../pages/Companies/EditCompany'

import EditUser from '../../pages/Companies/EditUser'
import CompaniesOrders from '../../pages/Companies/Orders'
import CreateCompanyOrder from '../../pages/Companies/CreateOrder'
import EditCompanyOrder from '../../pages/Companies/EditOrder'

import Uniforms from '../../pages/Uniforms/Uniforms'
import CreateUniform from '../../pages/Uniforms/CreateUniform'
import EditUniform from '../../pages/Uniforms/EditUniform'

import Genre from '../../pages/Genre/Genre'
import CreateGenre from '../../pages/Genre/CreateGenre'
import EditGenre from '../../pages/Genre/EditGenre'

import { useAuth } from '../../hooks/useAuth'
import AuthRoute from '../Routes/AuthRoute'
import { ROLES } from '../../constants/common'
import MiniProgram from '../../pages/MiniProgram/MiniProgram'
import CreateSection from '../../pages/MiniProgram/CreateSection'
import EditSection from '../../pages/MiniProgram/EditSection'
import CreateProductTag from '../../pages/Tags/CreateProductTag'
import EditProductTag from '../../pages/Tags/EditProductTag'
import ProductTags from '../../pages/Tags/ProductTags'
import CreateProductType from '../../pages/ProductTypes/CreateProductType'
import EditProductType from '../../pages/ProductTypes/EditProductType'
import ProductTypes from '../../pages/ProductTypes/ProductTypes'
import ProductPrices from '../../pages/ProductPrices/ProductPrices'

import Stores from '../../pages/Stores/Stores'
import CreateStore from '../../pages/Stores/CreateStore'
import EditStore from '../../pages/Stores/EditStore'

import ProductFranchises from '../../pages/ProductFranchises/ProductFranchises'
import EditProductFranchises from '../../pages/ProductFranchises/EditProductFranchises'
import Loader from '../../components/Loader'
import { updateCurrentStore } from '../../network/Users'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import EditProductPrice from '../../pages/ProductPrices/EditProductPrices'
import ViewProductPrices from '../../pages/ProductPrices/ViewProductPrices'

const { Content } = Layout

const Dashboard = ({ history }) => {
  const { signOut, user, refreshStore, token } = useAuth()
  const [store, setStore] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const showStoreSelectionModal = () => {
      setIsModalVisible(true)
    }

    const showErrorMessageModal = () => {
      setIsErrorModalVisible(true)
    }

    if (user && token) {
      let currentStore = user.stores.find(
        (store) => store.store === user.currentStore
      )
      if (!currentStore && user.stores.length > 0) {
        showStoreSelectionModal() // If the user has no current store, show the modal to select a default store
      }
      if (!currentStore && user.stores.length <= 0) {
        showErrorMessageModal() // If the user has no store att all display the message error and logout
      }
      if (currentStore) {
        setStore(currentStore)
      }
    }
  }, [user, token])

  const handleStoreSelect = (value) => {
    handleStoreChange(value)
    setIsModalVisible(false)
  }

  const handleStoreChange = async (storeId) => {
    setLoading(true)
    try {
      await updateCurrentStore(storeId)
      const currentStore = user.stores.find((store) => store.store === storeId)
      if (currentStore) {
        setStore(currentStore)
      }
      history.push('/')
      await refreshStore()
    } catch (error) {
      console.error('Failed to switch store:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleErrorModalClose = () => {
    setIsErrorModalVisible(false)
    signOut() // Logout the user after the error modal is closed
  }

  return (
    <>
      <Modal
        title="Select a Store"
        visible={isModalVisible}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <Select
          style={{ width: '100%' }}
          placeholder="Select a store"
          onChange={handleStoreSelect}
        >
          {user.stores.map((store) => (
            <Select.Option key={store.store} value={store.store}>
              {store.name}
            </Select.Option>
          ))}
        </Select>
      </Modal>

      <Modal
        title="Error"
        visible={isErrorModalVisible}
        onOk={handleErrorModalClose}
        closable={false}
        maskClosable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>
          No store available for this user. Please contact an administrator.
        </p>
      </Modal>

      <div
        style={{
          backgroundColor: '#000',
          height: '50px',
          padding: '0 1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundImage: store?.logo?.uri
              ? `url(${process.env.REACT_APP_API_URL}/storage/store-logo/${store.logo.uri})`
              : !user?.franchiseStore
              ? 'url(/images/whitelogo.svg)'
              : 'none',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: '100%',
            width: '100%',
          }}
        />

        <div>
          {!user || !user.stores || !store ? (
            <Loader style={{ marginTop: '0px', marginRight: '20px' }} />
          ) : user.stores.length > 1 ? (
            <Select
              onChange={handleStoreChange}
              placeholder="Select a store"
              style={{ marginRight: '20px', width: '150px' }}
              defaultValue={'Store: ' + store?.name || null}
            >
              {user.stores.map((store) => (
                <Select.Option key={store.store} value={store.store}>
                  {store.name}
                </Select.Option>
              ))}
            </Select>
          ) : null}
        </div>

        {/* <Tooltip title={timeBeforeExpire}> */}
        <LogoutOutlined
          onClick={signOut}
          style={{ color: '#fff', cursor: 'pointer' }}
        />
        {/* </Tooltip> */}
      </div>

      <Layout style={{ height: 'calc(100vh - 50px)' }}>
        {store && <NavigationDrawer logo={store.logo} loading={loading} />}

        <Layout
          className="site-layout"
          style={{ height: '95vh', overflowX: 'hidden', overflowY: 'scroll' }}
        >
          <Content>
            <Switch>
              <AuthRoute
                path="/metrics"
                component={Metrics}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.MARKETING, ROLES.PRODUCTION]}
              />

              <AuthRoute
                path="/members/create"
                component={CreateMember}
                user={user}
              />

              <AuthRoute
                path="/members/:memberId"
                component={EditMember}
                user={user}
              />
              <AuthRoute path="/members" component={Members} user={user} />

              <AuthRoute
                path="/stores"
                component={Stores}
                user={user}
                role={[
                  ROLES.STORE_MANAGER,
                  ROLES.STORE_ASSISTANT,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION,
                ]}
              />

              <AuthRoute
                path="/store/create"
                component={CreateStore}
                user={user}
                role={[]}
                franchise={user.franchiseStore}
              />

              <AuthRoute
                path="/store/:storeId"
                component={EditStore}
                user={user}
                role={[]}
              />

              <AuthRoute
                path="/categories/create/:storeId"
                component={CreateCategory}
                user={user}
                role={[ROLES.MARKETING, ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/categories/:categoryId/:storeId"
                component={EditCategories}
                user={user}
                role={[ROLES.MARKETING, ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/categories"
                component={Categories}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.MARKETING, ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />

              <AuthRoute
                path="/product-types/create/:storeId"
                component={CreateProductType}
                user={user}
                role={[ROLES.MARKETING]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/product-types/:productTypeId/"
                component={EditProductType}
                user={user}
                role={[ROLES.MARKETING]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/product-types"
                component={ProductTypes}
                user={user}
                role={[ROLES.MARKETING]}
                // franchise={user.franchiseStore}
              />

              <AuthRoute
                path="/products/create/:storeId"
                component={CreateProduct}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.MARKETING, ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/products/:productId/:storeId"
                component={EditProduct}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.MARKETING, ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/products"
                component={Products}
                user={user}
                role={[
                  ROLES.STORE_MANAGER,
                  ROLES.STORE_ASSISTANT,
                  ROLES.MARKETING,
                  ROLES.PRODUCTION,
                ]}
                // franchise={user.franchiseStore}
              />

              <AuthRoute
                path="/clients/create/:storeId"
                component={CreateClient}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.STORE_ASSISTANT]}
              />
              <Route path="/clients/:clientId/view" component={ViewClient} />
              <AuthRoute
                path="/clients/:clientId"
                component={EditClient}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.STORE_ASSISTANT]}
              />
              <Route
                path="/clients"
                component={Clients}
                franchise={user.franchiseStore}
              />

              <AuthRoute
                path="/orders/create/new/:storeId"
                component={CreateClient}
                user={user}
                role={[
                  ROLES.STORE_MANAGER,
                  ROLES.STORE_ASSISTANT,
                  ROLES.PRODUCTION,
                ]}
              />
              <AuthRoute
                path="/orders/create/select"
                component={Clients}
                user={user}
                role={[
                  ROLES.STORE_MANAGER,
                  ROLES.STORE_ASSISTANT,
                  ROLES.PRODUCTION,
                ]}
              />
              <AuthRoute
                path="/orders/create/:userId/store/:storeId"
                component={CreateOrder}
                user={user}
                role={[
                  ROLES.STORE_MANAGER,
                  ROLES.STORE_ASSISTANT,
                  ROLES.PRODUCTION,
                ]}
              />
              <AuthRoute
                path="/orders/create"
                exact
                component={ChooseTypeOrder}
                user={user}
                role={[
                  ROLES.STORE_MANAGER,
                  ROLES.STORE_ASSISTANT,
                  ROLES.PRODUCTION,
                ]}
              />

              <AuthRoute
                path="/adjustments/create/new/:storeId"
                component={CreateClient}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.STORE_ASSISTANT]}
              />
              <AuthRoute
                path="/adjustments/create/select"
                component={Clients}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.STORE_ASSISTANT]}
              />
              <AuthRoute
                path="/adjustments/create/:userId/store/:storeId"
                component={CreateAdjustment}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.STORE_ASSISTANT]}
                franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/adjustments/create"
                exact
                component={ChooseTypeOrder}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.STORE_ASSISTANT]}
                franchise={user.franchiseStore}
              />

              <AuthRoute
                path="/orders/:orderId/edit/store/:storeId"
                component={ModifyOrder}
                user={user}
                role={[ROLES.STORE_MANAGER]}
              />
              <Route
                path="/orders/:orderId/store/:storeId"
                component={EditOrder}
              />
              <Route path="/orders" component={Orders} />

              <AuthRoute
                path="/uniforms/create/:storeId"
                component={CreateUniform}
                user={user}
                role={[ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/uniforms/:uniformId"
                component={EditUniform}
                user={user}
                role={[ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/uniforms"
                component={Uniforms}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />

              <AuthRoute
                path="/companies/create/:storeId"
                component={CreateCompany}
                user={user}
                role={[ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/companies/orders/create/:storeId"
                component={CreateCompanyOrder}
                user={user}
                role={[ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/companies/orders/:orderId"
                component={EditCompanyOrder}
                user={user}
                role={[ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/companies/orders"
                component={CompaniesOrders}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/companies/customers/:customerId"
                component={EditUser}
                user={user}
                role={[ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/companies/:companyId"
                component={EditCompany}
                user={user}
                role={[ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/companies"
                component={Companies}
                user={user}
                role={[ROLES.STORE_MANAGER, ROLES.PRODUCTION]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/genres"
                exact
                component={Genre}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/genres/create"
                component={CreateGenre}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/genres/:genreId"
                component={EditGenre}
                user={user}
                role={[]}
              />
              <AuthRoute
                path="/mp/create"
                component={CreateSection}
                user={user}
                role={[ROLES.MARKETING]}
                franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/mp/:sectionId"
                component={EditSection}
                user={user}
                role={[ROLES.MARKETING]}
                franchise={user.franchiseStore}
              />
              <AuthRoute
                path="/mp"
                component={MiniProgram}
                user={user}
                role={[ROLES.MARKETING]}
                franchise={user.franchiseStore}
              />
              <AuthRoute
                path={'/product-tags/create/:storeId'}
                component={CreateProductTag}
                user={user}
                role={[ROLES.MARKETING]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path={'/product-tags/:tagId'}
                component={EditProductTag}
                user={user}
                role={[ROLES.MARKETING]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path={'/product-tags'}
                component={ProductTags}
                user={user}
                role={[ROLES.MARKETING]}
                // franchise={user.franchiseStore}
              />
              <AuthRoute
                path={`/product-prices`}
                component={ProductPrices}
                user={user}
                role={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              />
              <AuthRoute
                path={`/product-price/view/:key`}
                component={ViewProductPrices}
                user={user}
                role={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}
              />
              <AuthRoute
                path={`/product-price/:key/:storeId`}
                component={EditProductPrice}
                user={user}
                role={[]}
              />
              <AuthRoute
                path={'/product-franchises'}
                component={ProductFranchises}
                user={user}
                role={[]}
                franchise={user.franchiseStore}
              />

              <AuthRoute
                path={'/product-franchise/:storeId'}
                component={EditProductFranchises}
                user={user}
                role={[]}
                franchise={user.franchiseStore}
              />

              {/* <Route path="/auth/reset" component={Reset} /> */}
              <Route path="/" component={Home} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

export default withRouter(Dashboard)
