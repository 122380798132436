import {
  Button,
  Card,
  Image,
  Input,
  InputNumber,
  message,
  PageHeader,
  Radio,
  Select,
  Space,
  Form as StyleForm,
  Tabs,
  Typography,
} from 'antd'
import { Form, Formik } from 'formik'
import get from 'lodash.get'
import set from 'lodash.set'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import FormContent from './FormContent'

import Error from '../../components/Error'
import Loader from '../../components/Loader'
import { formOptions, Payment as PaymentForm } from '../../forms/blocks'
import { adjustmentInfos } from '../../forms/blocks/Adjustment'
import { getClientDetails } from '../../network/Clients'
import {
  getAdjustmentExcel,
  getOrderDetails,
  getOrderExcel,
  updateOrder,
} from '../../network/Orders'
import { getProductDetailsComplete } from '../../network/Products'
import { PRIORITY_MAP } from '../../utils/utils'
import { getProductPrices } from '../../network/ProductPrices'
import { getCurrencySymbol } from '../../utils/currencyUtils'
import { useAuth } from '../../hooks/useAuth'

const { TabPane } = Tabs
const { Text, Title } = Typography

const EditOrder = ({ history }) => {
  const [measureSelected, setMeasureSelected] = useState(null)
  const queryClient = useQueryClient()
  let { orderId } = useParams()
  const { storeId } = useParams()

  const keyQuery = ['getOrderDetails', { id: orderId }]
  const orderDetails = useQuery(keyQuery, getOrderDetails, {
    refetchOnWindowFocus: false,
  })

  const isAdjustment =
    get(orderDetails, 'data.orderProducts', []).findIndex(
      (e) => e.orderProductType === 'adjustment'
    ) !== -1

  const handleGoBack = () => {
    const queryParams = new URLSearchParams(window.location.search)
    history.push(`/orders?filterValues=${queryParams.get('filterValues')}`) // Pass JSON string back
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={
          measureSelected === null
            ? 'Order summary'
            : get(
                orderDetails,
                `data.orderProducts.${measureSelected}.orderProductType`,
                ''
              ) === 'adjustment'
            ? 'Edit Adjustment'
            : get(orderDetails, 'data.validated', false)
            ? 'Edit'
            : 'Edit Measures'
        }
        onBack={
          measureSelected === null
            ? handleGoBack
            : () => setMeasureSelected(null)
        }
        style={{ backgroundColor: '#fff' }}
      />
      {orderDetails.isError ? (
        <Error retry={() => orderDetails.refetch()} />
      ) : orderDetails.isLoading ? (
        <Loader />
      ) : measureSelected === null ? (
        <Tabs
          className="orders-tabs"
          defaultActiveKey="1"
          tabBarExtraContent={
            isAdjustment ? null : (
              <Button
                disabled={
                  orderDetails.isLoading ||
                  get(orderDetails, 'data.validated', false)
                }
                onClick={() =>
                  history.push(`/orders/${orderId}/edit/store/${storeId}`)
                }
              >
                Add sku
              </Button>
            )
          }
        >
          <TabPane tab="Payment" key="1">
            <Payment data={get(orderDetails, 'data', {})} orderId={orderId} />
          </TabPane>
          <TabPane tab="Details" key="2">
            <Detail
              data={get(orderDetails, 'data', {})}
              orderId={orderId}
              editMeasure={setMeasureSelected}
              validate={() =>
                queryClient.setQueryData(keyQuery, (oldData) => ({
                  ...oldData,
                  validated: true,
                }))
              }
            />
          </TabPane>
        </Tabs>
      ) : get(
          orderDetails,
          `data.orderProducts.${measureSelected}.orderProductType`,
          ''
        ) === 'adjustment' ? (
        <EditAdjustment
          closeMeasure={() => setMeasureSelected(null)}
          data={get(orderDetails, 'data', {})}
          productIndex={measureSelected}
        />
      ) : get(orderDetails, 'data.validated', false) ? (
        <EditMeasure
          closeMeasure={() => setMeasureSelected(null)}
          data={get(orderDetails, 'data', {})}
          productIndex={measureSelected}
        />
      ) : (
        <EditOrderInfos
          closeMeasure={() => setMeasureSelected(null)}
          data={get(orderDetails, 'data', {})}
          productIndex={measureSelected}
        />
      )}
    </>
  )
}

const getFromKey = (prod) =>
  prod.orderProductType === 'custom'
    ? prod.orderCustom?.type
    : prod.orderProductType

const getCollectFrom = (blocks) => {
  let buildForm = []

  blocks.forEach((e) => {
    buildForm = [...buildForm, ...formOptions[e].measuresForms]
  })

  return buildForm
}

const getCollectFromForm = (blocks) => {
  let buildForm = []

  blocks.forEach((e) => {
    buildForm = [...buildForm, ...formOptions[e].forms]
  })

  return buildForm
}

const CollectionLabel = ({ ...prod }) => {
  const product = useQuery(
    [
      'getProductDetailsComplete',
      {
        id: get(prod, 'orderCollection.product', ''),
      },
    ],
    getProductDetailsComplete,
    {
      refetchOnWindowFocus: false,
    }
  )

  return (
    <p>
      <strong>
        {`x${get(prod, 'orderCollection.quantity', 0) || 1} ${
          product.data
            ? `${get(product, 'data.sku', '')} ${get(
                product,
                'data.name.en',
                ''
              )}`
            : 'Collection'
        }: `}
      </strong>
      {`CNY ${get(prod, 'orderCollection.price', 0)}`}
    </p>
  )
}

const Payment = ({ data, orderId }) => {
  const formsPayment = [{ form: PaymentForm, keys: '' }]
  const { user } = useAuth()
  const { storeId } = useParams()
  const currentStore = user.stores.find((store) => store.store === storeId)

  const queryClient = useQueryClient()
  const editOrders = useMutation((val) => updateOrder(...val), {
    onSuccess: (data) => {
      message.success('order total updated !')
      queryClient.setQueryData(
        [
          'getOrderDetails',
          {
            id: orderId,
          },
        ],
        () => data
      )
    },
  })

  return (
    <Formik
      initialValues={data}
      validate={(values) => {
        const errors = {}

        if (values.balance < 0) {
          errors.balance = 'Balance must be greater than 0'
        }
        return errors
      }}
      onSubmit={async (values) => {
        editOrders.mutate([values, orderId])
      }}
    >
      {({ values, errors, handleChange, ...rest }) => (
        <Form style={{ margin: '0px 14px' }}>
          <Card title="Payment details">
            <div
              style={{
                marginBottom: '16px',
                width: '50%',
                maxWidth: '50%',
                position: 'relative',
              }}
            >
              {(currentStore?.logo?.uri || !user?.franchiseStore) && (
                <img
                  src={
                    currentStore?.logo?.uri
                      ? `${process.env.REACT_APP_API_URL}/storage/store-logo/${currentStore.logo.uri}`
                      : '/images/atelier_logo.png'
                  }
                  alt="store logo"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    display: 'block',
                    maxHeight: '150px',
                    objectFit: 'contain',
                  }}
                  loading="lazy"
                />
              )}
            </div>
            <table
              style={{
                width: '50%',
                borderCollapse: 'collapse',
                border: '1px solid #ddd',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: '8px',
                      border: '1px solid #ddd',
                      backgroundColor: '#f2f2f2',
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: '8px',
                      border: '1px solid #ddd',
                      backgroundColor: '#f2f2f2',
                    }}
                  >
                    Product
                  </th>
                  <th
                    style={{
                      textAlign: 'left',
                      padding: '8px',
                      border: '1px solid #ddd',
                      backgroundColor: '#f2f2f2',
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {values?.orderProducts
                  ?.map((prod, index) => {
                    const prodType = getFromKey(prod)
                    return {
                      ...prod,
                      priority: PRIORITY_MAP[prodType],
                    }
                  })
                  .sort((a, b) => b.priority - a.priority)
                  .map((prod, index) => {
                    const prodType = getFromKey(prod)
                    let total = 0
                    let price = 0
                    let quantity = 1 // Default quantity

                    if (prodType === 'collection') {
                      price = get(prod, 'orderCollection.price', 0)
                    } else if (prodType === 'adjustment') {
                      price = get(prod, 'orderAdjustment.price', 0)
                    } else {
                      price = get(prod, 'orderCustom.price', 0)
                    }

                    if (prodType === 'adjustment') {
                      quantity = get(
                        prod,
                        `orderAdjustment.adjustmentPositions`,
                        []
                      ).length
                      return (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? '#fff' : '#f9f9f9',
                            borderBottom: '1px solid #ddd',
                          }}
                        >
                          <td
                            style={{ border: '1px solid #ddd', padding: '8px' }}
                          >
                            {quantity}
                          </td>
                          <td
                            style={{ border: '1px solid #ddd', padding: '8px' }}
                          >
                            Adjustment
                          </td>
                          <td
                            style={{ border: '1px solid #ddd', padding: '8px' }}
                          >
                            {data.currency
                              ? data.currency.toUpperCase()
                              : 'CNY'}{' '}
                            {get(prod, 'orderAdjustment.price', 0)}
                          </td>
                        </tr>
                      )
                    }

                    // Extra shirts
                    let extraShirts = get(
                      prod,
                      'orderCustom.orderCustomShirt.extraShirts',
                      []
                    )
                    if (extraShirts) {
                      extraShirts.forEach((item) => {
                        price += get(item, 'priceNum', 0)
                      })
                      formOptions[prodType].forms.forEach((e) => {
                        total += get(prod, `${e.key}.extraShirts`, []).length
                      })
                    }

                    // Normal product
                    formOptions[prodType].forms.forEach((e) => {
                      total += get(prod, `${e.key}.addMore`, 0)
                    })

                    quantity = total + 1 // Update quantity for normal products

                    return (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#fff' : '#f9f9f9',
                          borderBottom: '1px solid #ddd',
                        }}
                      >
                        <td
                          style={{ border: '1px solid #ddd', padding: '8px' }}
                        >
                          {quantity}
                        </td>
                        <td
                          style={{ border: '1px solid #ddd', padding: '8px' }}
                        >
                          {formOptions[prodType].name}
                        </td>
                        <td
                          style={{ border: '1px solid #ddd', padding: '8px' }}
                        >
                          {data.currency ? data.currency.toUpperCase() : 'CNY'}{' '}
                          {price}
                        </td>
                      </tr>
                    )
                  })}
                {/* Total */}
                <tr>
                  <td
                    style={{
                      padding: '8px',
                      fontWeight: 'bold',
                      borderTop: '2px solid #000',
                    }}
                  ></td>
                  <td
                    style={{
                      padding: '8px',
                      fontWeight: 'bold',
                      borderTop: '2px solid #000',
                    }}
                  >
                    Total:
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      fontWeight: 'bold',
                      borderTop: '2px solid #000',
                    }}
                  >
                    {data.currency ? data.currency.toUpperCase() : 'CNY'}{' '}
                    {calculateTotal(values)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ padding: '8px', fontWeight: 'bold' }}>
                    Discount:
                  </td>
                  <td style={{ padding: '8px' }}>
                    {data.currency ? data.currency.toUpperCase() : 'CNY'}{' '}
                    {values.discount || 0}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ padding: '8px', fontWeight: 'bold' }}>
                    Deposit 1:
                  </td>
                  <td style={{ padding: '8px' }}>
                    {data.currency ? data.currency.toUpperCase() : 'CNY'}{' '}
                    {values.deposit || 0}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="text-strong" style={{ padding: '8px' }}>
                    Deposit 2:
                  </td>
                  <td style={{ padding: '8px' }}>
                    {data.currency ? data.currency.toUpperCase() : 'CNY'}{' '}
                    {values.deposit2 || 0}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td
                    className="text-strong"
                    style={{
                      padding: '8px',
                    }}
                  >
                    Balance:
                  </td>
                  <td
                    style={{
                      padding: '8px',
                    }}
                  >
                    {data.currency ? data.currency.toUpperCase() : 'CNY'}{' '}
                    {calculateBalance(values)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
          <FormContent
            values={values}
            errors={errors}
            path=""
            handleChange={(e) => {
              handleChange(e)

              let discount =
                e.target.name === 'discount'
                  ? e.target.value
                  : values.discount || 0

              let deposit

              if (e.target.name === 'deposit') {
                deposit = e.target.value + get(values, 'deposit2', 0)
              } else if (e.target.name === 'deposit2') {
                deposit = get(values, 'deposit', 0) + e.target.value
              } else {
                deposit = get(values, 'deposit', 0) + get(values, 'deposit2', 0)
              }

              handleChange({
                target: {
                  name: 'balance',
                  value: values.totalSpent - discount - deposit,
                },
              })
            }}
            forms={formsPayment}
          />
          <Space style={{ margin: '14px 0px' }}>
            <Button
              loading={editOrders.isLoading}
              disabled={Object.keys(errors).length}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
            <Button
              disabled={editOrders.isLoading}
              onClick={() => rest.resetForm()}
              type="secondary"
            >
              Cancel
            </Button>
          </Space>
        </Form>
      )}
    </Formik>
  )
}

// const Payment2 = ({ data, orderId }) => {
//   const queryClient = useQueryClient()
//   const editOrders = useMutation((val) => updateOrder(...val), {
//     onSuccess: (data) => {
//       message.success('order total updated !')
//       queryClient.setQueryData(
//         [
//           'getOrderDetails',
//           {
//             id: orderId,
//           },
//         ],
//         () => data
//       )
//     },
//   })

//   return (
//     <Formik
//       initialValues={data}
//       validate={(values) => {
//         const errors = {}

//         if (values.balance < 0) {
//           errors.balance = 'Balance must be greater than 0'
//         }
//         return errors
//       }}
//       onSubmit={async (values) => {
//         editOrders.mutate([values, orderId])
//       }}
//     >
//       {({ values, errors, handleChange, ...rest }) => (
//         <Form>
//           <Space
//             direction="vertical"
//             style={{ width: '100%', padding: '0px 24px' }}
//           >
//             <Card title="Payment details">
//               {values.orderProducts.map((prod, index) => {
//                 const prodType = getFromKey(prod)
//                 let total = 0
//                 formOptions[prodType].forms.forEach(
//                   (e) => (total += get(prod, `${e.key}.addMore`, 0))
//                 )
//                 if (prodType === 'collection') {
//                   return <CollectionLabel {...prod} />
//                 } else if (prodType === 'adjustment') {
//                   return (
//                     <p>{`x1 adjustment: CNY ${get(
//                       prod,
//                       'orderCustom.price',
//                       0
//                     )}`}</p>
//                   )
//                 } else {
//                   return (
//                     <p>{`x${total + 1} ${formOptions[prodType].name}: CNY ${get(
//                       prod,
//                       'orderCustom.price',
//                       0
//                     )}`}</p>
//                   )
//                 }
//               })}
//             </Card>
//             <Card title=""></Card>
//             <Card title="Total Summary">
//               <StyleForm layout="vertical" style={{ width: '100%' }}>
//                 <StyleForm.Item label="Total spend">
//                   <InputNumber
//                     min={0}
//                     name="totalSpent"
//                     style={{ width: '33%' }}
//                     onChange={(e) =>
//                       handleChange({
//                         target: {
//                           name: 'totalSpent',
//                           value: e,
//                         },
//                       })
//                     }
//                     value={values.totalSpent}
//                     disabled={true}
//                   />
//                 </StyleForm.Item>
//                 <StyleForm.Item label="Discount">
//                   <InputNumber
//                     min={0}
//                     name="discount"
//                     style={{ width: '33%' }}
//                     onChange={(e) => {
//                       handleChange({
//                         target: {
//                           name: 'discount',
//                           value: e,
//                         },
//                       })

//                       let val = get(values, 'totalSpent', 0)
//                       val = parseFloat((val - e).toFixed(2))
//                       val = parseFloat(
//                         (val - get(values, 'deposit', 0)).toFixed(2)
//                       )

//                       handleChange({
//                         target: {
//                           name: 'balance',
//                           value: val,
//                         },
//                       })
//                     }}
//                     value={values.discount}
//                   />
//                 </StyleForm.Item>
//                 <StyleForm.Item label="Deposit">
//                   <InputNumber
//                     min={0}
//                     name="deposit"
//                     style={{ width: '33%' }}
//                     onChange={(e) => {
//                       handleChange({
//                         target: {
//                           name: 'deposit',
//                           value: e,
//                         },
//                       })

//                       let val = get(values, 'totalSpent', 0)
//                       val = parseFloat(
//                         (val - get(values, 'discount', 0)).toFixed(2)
//                       )
//                       val = parseFloat((val - e).toFixed(2))

//                       handleChange({
//                         target: {
//                           name: 'balance',
//                           value: val,
//                         },
//                       })
//                     }}
//                     value={values.deposit}
//                   />
//                 </StyleForm.Item>
//                 <StyleForm.Item
//                   label="Balance"
//                   validateStatus={errors.balance ? 'error' : null}
//                   help={get(errors, 'balance', null)}
//                 >
//                   <InputNumber
//                     min={0}
//                     name="balance"
//                     style={{ width: '33%' }}
//                     onChange={(e) =>
//                       handleChange({
//                         target: {
//                           name: 'balance',
//                           value: e,
//                         },
//                       })
//                     }
//                     value={values.balance}
//                     disabled={true}
//                   />
//                 </StyleForm.Item>
//               </StyleForm>
//             </Card>
//             <Space>
//               <Button
//                 loading={editOrders.isLoading}
//                 disabled={Object.keys(errors).length}
//                 type="primary"
//                 htmlType="submit"
//               >
//                 Save
//               </Button>
//               <Button
//                 disabled={editOrders.isLoading}
//                 onClick={() => rest.resetForm()}
//                 type="secondary"
//               >
//                 Cancel
//               </Button>
//             </Space>
//           </Space>
//         </Form>
//       )}
//     </Formik>
//   )
// }

const Detail = ({ data, editMeasure, validate }) => {
  const clientDetails = useQuery(
    [
      'getClientDetails',
      {
        id: get(data, 'customer', ''),
      },
    ],
    getClientDetails,
    {
      refetchOnWindowFocus: false,
    }
  )

  const [isExporting, setIsExporting] = useState(false)

  const validation = useMutation(
    () => updateOrder({ ...data, validated: true }, data.id),
    {
      onSuccess: validate,
    }
  )

  const exportOrder = (data) => {
    setIsExporting(true)
    getOrderExcel(get(data, 'id', ''))
      .then((file) => {
        const url = window.URL.createObjectURL(new Blob([file]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Order-${get(data, 'orderID', 'order')}-${get(
            clientDetails,
            'data.firstName',
            ''
          )} ${get(clientDetails, 'data.lastName', '')}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        console.log('error', error)
        alert(`An error occurred while exporting the order`)
      })
      .finally(() => {
        setIsExporting(false)
      })
  }

  const exportAdjustment = (data) => {
    setIsExporting(true)
    getAdjustmentExcel(get(data, 'id', ''))
      .then((file) => {
        const url = window.URL.createObjectURL(new Blob([file]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Order-${get(data, 'orderID', 'order')}-${get(
            clientDetails,
            'data.firstName',
            ''
          )} ${get(clientDetails, 'data.lastName', '')}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        console.log('error', error)
        alert(`An error occurred while exporting the order`)
      })
      .finally(() => {
        setIsExporting(false)
      })
  }

  return (
    <Space
      className="detail-general-order"
      direction="vertical"
      style={{ width: '100%', padding: '0px 24px' }}
    >
      <Card title="General information">
        <StyleForm layout="vertical" style={{ width: '100%' }}>
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Space direction="vertical" style={{ width: '100%' }} size="small">
              <Text>Order ID: {get(data, 'orderID', '')}</Text>
              <Text>
                Order date: {moment(get(data, 'date', '')).format('DD/MM/yyyy')}
                {/* Order date: {moment().format('MMMM Do YYYY, h:mm:ss a')} */}
              </Text>
              <Text>Channel: {get(data, 'channel', '')}</Text>
            </Space>

            <Space direction="vertical" size="small">
              <Text>
                Customer: {get(clientDetails, 'data.firstName', '')}{' '}
                {get(clientDetails, 'data.lastName', '')}
              </Text>
              <Text>Comment: {get(data, 'address.comment', '')}</Text>
              <Text>
                Address: {get(data, 'address.address', '')}{' '}
                {get(data, 'address.area', '')}{' '}
                {get(data, 'address.zipCode', '')}{' '}
                {get(data, 'address.city', '')}
              </Text>
              <Text>Phone: {get(data, 'address.phone', '')}</Text>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }} size="small">
              <StyleForm.Item label="Delivery status">
                <Select
                  style={{ width: '33%' }}
                  defaultValue={data?.status}
                  disabled={true}
                  // onChange={handleChange}
                >
                  <Select.Option value="in_preparation">
                    In preparation
                  </Select.Option>
                  <Select.Option value="in_production">
                    In production
                  </Select.Option>
                  <Select.Option value="on_the_way_store">
                    On the way to store
                  </Select.Option>
                  <Select.Option value="on_the_way_client">
                    On the way to client
                  </Select.Option>
                  <Select.Option value="ready_to_pick_up">
                    Ready to pick up
                  </Select.Option>
                  <Select.Option value="order_finished">
                    Order finished
                  </Select.Option>
                  <Select.Option value="cancelled">Cancelled</Select.Option>
                </Select>
              </StyleForm.Item>

              <StyleForm.Item label="Deliver in store">
                <Radio.Group
                  style={{ width: '33%' }}
                  defaultValue="yes"
                  disabled={true}
                >
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </StyleForm.Item>
              {/* <StyleForm.Item label="Persona type">
                <Radio.Group
                  style={{ width: '33%' }}
                  defaultValue={data?.personaeType}
                  disabled={true}
                >
                  <Radio value="special_event">Special event</Radio>
                  <Radio value="wardrobe_update">Wardrobe update</Radio>
                </Radio.Group>
              </StyleForm.Item> */}
            </Space>
            {get(data, 'orderProducts').findIndex(
              (e) => e.orderProductType === 'adjustment'
            ) === -1 && (
              <Space>
                <Button
                  disabled={
                    clientDetails.isLoading ||
                    !data ||
                    get(data, 'validated', false)
                  }
                  loading={validation.isLoading}
                  onClick={() => validation.mutate()}
                  type="primary"
                >
                  Start production
                </Button>
                <Button
                  loading={isExporting}
                  disabled={
                    isExporting ||
                    clientDetails.isLoading ||
                    !data ||
                    !get(data, 'validated', false)
                  }
                  onClick={() => exportOrder(data)}
                  type="primary"
                >
                  Export Excel
                </Button>
              </Space>
            )}
            {get(data, 'orderProducts').findIndex(
              (e) => e.orderProductType === 'adjustment'
            ) === 0 && (
              <Space>
                <Button
                  disabled={clientDetails.isLoading || !data}
                  onClick={() => exportAdjustment(data)}
                  type="primary"
                >
                  Export Excel
                </Button>
              </Space>
            )}
          </Space>
        </StyleForm>
      </Card>
      {data.orderProducts
        .map((prod, index) => {
          const prodType = getFromKey(prod)
          return {
            ...prod,
            index,
            priority: PRIORITY_MAP[prodType],
          }
        })
        .sort((a, b) => b.priority - a.priority)
        .map((prod) =>
          prod.orderProductType === 'adjustment' ? (
            <AdjustmentCard
              key={prod.index}
              product={prod}
              prodIndex={prod.index}
              measureSelected={() => editMeasure(prod.index)}
              orderIDClean={get(data, 'orderID', '')}
              validated={get(data, 'validated', false)}
            />
          ) : (
            <MeasureCard
              key={prod.index}
              product={prod}
              prodIndex={prod.index}
              measureSelected={() => editMeasure(prod.index)}
              orderIDClean={get(data, 'orderID', '')}
              validated={get(data, 'validated', false)}
              orderDetails={data}
            />
          )
        )}
    </Space>
  )
}

const AdjustmentCard = ({ product, measureSelected }) => {
  return (
    <>
      <Card
        style={{ width: '100%' }}
        title={`Adjustment ${
          adjustmentInfos[get(product, `orderAdjustment.type`, 'vest')].name
        }`}
      >
        {adjustmentInfos[
          get(product, `orderAdjustment.type`, 'vest')
        ].forms.map((fff) =>
          fff.form.map((form) =>
            form.sections.map((section) =>
              section.canAdd
                ? get(product, `orderAdjustment.adjustmentPositions`, []).map(
                    (adj, idex) => [
                      <>
                        <Text underline key={`title${idex}`}>
                          <strong>{`${section.display} ${idex + 1}`}</strong>
                        </Text>
                        <br />
                      </>,
                      section.fields.map((field) => {
                        const value = get(adj, field.key.join('.'), '')

                        if (
                          (field.isHiding &&
                            field.isHiding(
                              product,
                              `orderAdjustment.adjustmentPositions.${idex}.`
                            )) ||
                          (!value && value !== 0)
                        ) {
                          return null
                        }

                        return (
                          <>
                            <Text key={`${field.key.join('.')}${idex}`}>
                              <strong>{field.label}: </strong>
                              {field.type === 'multiupload'
                                ? [
                                    <br />,
                                    value?.map((pic) => (
                                      <Image
                                        // width={125}
                                        height={100}
                                        src={`${process.env.REACT_APP_API_URL}${field.storageURI}/${pic.uri}`}
                                      />
                                    )),
                                  ]
                                : value}
                            </Text>
                            <br />
                          </>
                        )
                      }),
                    ]
                  )
                : section.fields.map((field) => {
                    const value = get(
                      product,
                      `orderAdjustment.${field.key.join('.')}`,
                      ''
                    )

                    if (
                      (field.isHiding &&
                        field.isHiding(product, `orderAdjustment.`)) ||
                      (!value && value !== 0)
                    ) {
                      return null
                    }

                    return (
                      <>
                        <Text key={field.key.join('.')}>
                          <strong>{field.label}: </strong>
                          {field.type === 'multiupload'
                            ? [
                                <br />,
                                value?.map((pic) => (
                                  <Image
                                    height={100}
                                    src={`${process.env.REACT_APP_API_URL}${field.storageURI}/${pic.uri}`}
                                  />
                                )),
                              ]
                            : Array.isArray(value)
                            ? value.join(', ')
                            : value}
                        </Text>
                        <br />
                      </>
                    )
                  })
            )
          )
        )}
      </Card>
      <Card style={{ width: '100%', marginTop: -1 }}>
        <Space direction="vertical">
          <Text type="secondary">
            QTY:{' '}
            {get(product, `orderAdjustment.adjustmentPositions`, []).length}
          </Text>
          <Text strong>¥{get(product, `orderAdjustment.price`, 0)}</Text>
          <Space>
            <Button type="primary" onClick={measureSelected}>
              Modify
            </Button>
          </Space>
        </Space>
      </Card>
    </>
  )
}

const MeasureCard = ({ product, measureSelected, validated, orderDetails }) => {
  const [selectedMeasure, setSelectedMeasure] = useState('0')
  const isCollection = getFromKey(product) === 'collection'
  const productDetails = useQuery(
    [
      'getProductDetailsComplete',
      {
        id: get(product, 'orderCollection.product', ''),
      },
    ],
    getProductDetailsComplete,
    {
      refetchOnWindowFocus: false,
      enabled: isCollection,
    }
  )

  // if (!product.skuMeasurements?.length) {
  //   return null
  // }

  const calcTotal = () => {
    const type = getFromKey(product)

    switch (type) {
      case 'collection':
        return get(product, `orderCollection.price`, 0)
      case 'adjustment':
        return get(product, `orderAdjustment.price`, 0)
      default:
        let price = get(product, `orderCustom.price`, 0)
        let extraShirts = get(
          product,
          `orderCustom.orderCustomShirt.extraShirts`,
          []
        )
        if (extraShirts) {
          extraShirts.forEach((item) => {
            price += get(item, 'priceNum', 0)
          })
        }
        return price
    }
  }

  const getQty = () => {
    const type = getFromKey(product)

    switch (type) {
      case 'collection':
        return get(product, `orderCollection.quantity`) || 1
      case 'adjustment':
        return get(product, `orderAdjustment.addMore`, 0) + 1
      default:
        let addMore = get(product, `orderCustom.addMore`, 0) + 1
        let extraShirts = get(
          product,
          `orderCustom.orderCustomShirt.extraShirts`,
          []
        )
        let extraShirtsCount = extraShirts ? extraShirts.length : 0
        return addMore + extraShirtsCount
    }
  }

  const prodForms = formOptions[getFromKey(product)]
  const formsInf = isCollection
    ? getCollectFrom(get(productDetails, 'data.category.blocks', []))
    : prodForms.measuresForms

  const formsInfF = isCollection
    ? getCollectFromForm(get(productDetails, 'data.category.blocks', []))
    : prodForms.forms

  let symbol = ''
  if (orderDetails.currency) {
    symbol = getCurrencySymbol(orderDetails.currency)
  }

  return (
    <>
      {validated ? (
        <Card
          style={{ width: '100%' }}
          title={
            isCollection
              ? get(productDetails, 'data.name.en', '')
              : prodForms.name
          }
          tabList={[
            { key: 'opt', tab: 'Options' },
            ...product.skuMeasurements.map((e, i) => ({
              key: `${i}`,
              tab: `Measurement after adjustment ${i + 1}`,
            })),
          ]}
          activeTabKey={selectedMeasure}
          onTabChange={setSelectedMeasure}
        >
          {/* <Space direction="vertical"> */}
          {selectedMeasure === 'opt' ? (
            product.orderProductType === 'collection' ? (
              product.orderCollection.options ? (
                product.orderCollection.options.map((option, i) => {
                  const optionName = option?.subOptions?.find(
                    (e) => e.selected
                  )?.name

                  return (
                    <Text key={i}>
                      <strong>{option?.name?.en || option?.name?.zh}: </strong>
                      {optionName?.en || optionName?.zh}
                    </Text>
                  )
                })
              ) : (
                <Text>No Extra Option</Text>
              )
            ) : (
              formsInfF?.map((forms) =>
                forms?.form?.map((e) => [
                  e.display ? <Title level={5}>{e.display}:</Title> : null,
                  ...e.sections?.map((section) =>
                    section.canAdd && section.key.includes('extraShirts')
                      ? get(
                          product,
                          `${forms.key}.${section.key.join('.')}`,
                          []
                        )?.map((extraShirt, idex) => [
                          <>
                            <Text underline key={`title${idex}`}>
                              <strong>{`${section.display} ${
                                idex + 1
                              }`}</strong>
                            </Text>
                            <br />
                          </>,
                          section.fields.map((field) => {
                            let value = get(extraShirt, field.key.join('.'), '')

                            if (
                              (field.isHiding &&
                                field.isHiding(
                                  product,
                                  `${forms.key}.${section.key.join(
                                    '.'
                                  )}.${idex}.`
                                )) ||
                              (!value && value !== 0)
                            ) {
                              return null
                            }

                            if (!field.label) return null

                            if (field.onChangeRef) {
                              value =
                                value +
                                ' ' +
                                get(extraShirt, field.onChangeRef.join('.'), '')
                            }

                            return (
                              <>
                                <Text key={`${field.key.join('.')}${idex}`}>
                                  <strong>{field.label}: </strong>
                                  {value}
                                </Text>
                                <br />
                              </>
                            )
                          }),
                        ])
                      : section.fields?.map((g) => {
                          let value = get(
                            product,
                            `${forms.key}.${g.key.join('.')}`,
                            ''
                          )
                          if (
                            (g.isHiding &&
                              g.isHiding(product, `${forms.key}.`)) ||
                            (!value && value !== 0)
                          ) {
                            return null
                          }

                          if (g.options && Array.isArray(g.options)) {
                            const val = g.options.find(
                              (item) => item.key === value
                            )
                            if (val && val.label) {
                              value = val.label
                            }
                          }

                          return (
                            <>
                              <Text key={g.key.join('.')}>
                                <strong>{g.label}: </strong>
                                {Array.isArray(value)
                                  ? value.join(', ')
                                  : value}
                              </Text>
                              <br />
                            </>
                          )
                        })
                  ),
                ])
              )
            )
          ) : (
            formsInf?.map((froms) =>
              froms?.form?.map((e) => [
                <Title level={5}>{e.display}:</Title>,
                ...e.sections?.map((f) =>
                  f.fields?.map((g) => {
                    const value = get(
                      product,
                      `${froms.key.replace(
                        '0',
                        `${selectedMeasure}`
                      )}.${g.key.join('.')}`,
                      ''
                    )

                    if (typeof value === 'object') return null

                    if (!g.label) return null

                    if (
                      (g.isHiding &&
                        g.isHiding(
                          product,
                          `${froms.key.replace('0', `${selectedMeasure}`)}.`,
                          true
                        )) ||
                      (!value && value !== 0)
                    ) {
                      return null
                    }

                    return (
                      <>
                        <Text key={g.key.join('.')}>
                          <strong>{g.label}: </strong>
                          {Array.isArray(value) ? value.join(', ') : value}
                        </Text>
                        <br />
                      </>
                    )
                  })
                ),
              ])
            )
          )}
          {/* </Space> */}
        </Card>
      ) : (
        <Card
          style={{ width: '100%' }}
          title={
            isCollection
              ? get(productDetails, 'data.name.en', '')
              : prodForms.name
          }
          tabList={[
            { key: '0', tab: 'Option' },
            { key: '1', tab: 'Measurement', disabled: !formsInf },
          ]}
          activeTabKey={selectedMeasure}
          onTabChange={setSelectedMeasure}
        >
          {/* {console.log(formsInf, product)} */}
          {selectedMeasure === '0' ? (
            product.orderProductType === 'collection' ? (
              <CollectionOptions product={product} />
            ) : (
              <OptionDisplay formsInf={formsInfF} product={product} />
            )
          ) : (
            formsInf.map((forms) =>
              forms.form.map((e) => [
                <Title level={5}>{e.display}:</Title>,
                ...e.sections.map((f) =>
                  f.fields.map((g) => {
                    const value = get(
                      product,
                      `${forms.key}.${g.key.join('.')}`,
                      ''
                    )

                    if (typeof value === 'object') return null
                    if (!g.label) return null

                    if (
                      (g.isHiding &&
                        g.isHiding(product, `${forms.key}.`, true)) ||
                      (!value && value !== 0)
                    ) {
                      return null
                    }

                    return (
                      <>
                        <Text key={g.key.join('.')}>
                          <strong>{g.label}: </strong>
                          {Array.isArray(value) ? value.join(', ') : value}
                        </Text>
                        <br />
                      </>
                    )
                  })
                ),
              ])
            )
          )}
        </Card>
      )}
      <Card style={{ width: '100%', marginTop: -1 }}>
        <Space direction="vertical">
          <Text type="secondary">Personae Type</Text>
          <Text strong>{get(product, `orderCustom.personaeType`, null)}</Text>
        </Space>
      </Card>
      <Card style={{ width: '100%', marginTop: -1 }}>
        <Space direction="vertical">
          <Text type="secondary">QTY: {getQty()}</Text>
          <Text strong>
            {symbol}
            {calcTotal()}
          </Text>

          <Space>
            <Button
              disabled={!formsInf && selectedMeasure === '1'}
              type="primary"
              onClick={measureSelected}
            >
              Modify
            </Button>
          </Space>
        </Space>
      </Card>
    </>
  )
}

const CollectionOptions = ({ product }) => {
  return product?.orderCollection?.options ? (
    product?.orderCollection?.options?.map((option) => {
      const selectedOption = option?.subOptions?.find((sub) => sub.selected)

      return (
        <>
          <Text key={option._id}>
            <strong>{option?.name?.en || option?.name?.zh}: </strong>
            {selectedOption?.name?.en || selectedOption?.name?.zh}
          </Text>
          <br />
        </>
      )
    })
  ) : (
    <Text>No Extra Options</Text>
  )
}

const OptionDisplay = ({ formsInf, product }) => {
  return formsInf.map((forms) =>
    forms.form.map((e) => [
      e.display ? <Title level={5}>{e.display}:</Title> : null,
      ...e.sections?.map((section) =>
        section.canAdd && section.key.includes('extraShirts')
          ? get(product, `${forms.key}.${section.key.join('.')}`, [])?.map(
              (extraShirt, idex) => [
                <>
                  <Text underline key={`title${idex}`}>
                    <strong>{`${section.display} ${idex + 1}`}</strong>
                  </Text>
                  <br />
                </>,
                section.fields.map((field) => {
                  let value = get(extraShirt, field.key.join('.'), '')

                  if (
                    (field.isHiding &&
                      field.isHiding(
                        product,
                        `${forms.key}.${section.key.join('.')}.${idex}.`
                      )) ||
                    (!value && value !== 0)
                  ) {
                    return null
                  }

                  if (!field.label) return null

                  if (field.onChangeRef) {
                    value =
                      value +
                      ' ' +
                      get(extraShirt, field.onChangeRef.join('.'), '')
                  }

                  return (
                    <>
                      <Text key={`${field.key.join('.')}${idex}`}>
                        <strong>{field.label}: </strong>
                        {value}
                      </Text>
                      <br />
                    </>
                  )
                }),
              ]
            )
          : section.fields.map((g) => {
              const value = get(product, `${forms.key}.${g.key.join('.')}`, '')

              if (
                (g.isHiding && g.isHiding(product, `${forms.key}.`)) ||
                (!value && value !== 0)
              ) {
                return null
              }

              return (
                <>
                  <Text key={g.key.join('.')}>
                    <strong>{g.label}: </strong>
                    {Array.isArray(value) ? value.join(', ') : value}
                  </Text>
                  <br />
                </>
              )
            })
      ),
    ])
  )
}

const EditMeasure = ({ data, productIndex, closeMeasure }) => {
  let { orderId } = useParams()
  const [isCreating, setIsCreating] = useState(false)
  const queryClient = useQueryClient()
  const [activeKey, setActiveKey] = useState('0')

  const isCollection =
    getFromKey(data.orderProducts[productIndex]) === 'collection'

  const productDetails = useQuery(
    [
      'getProductDetailsComplete',
      {
        id: get(
          data,
          `orderProducts.${productIndex}.orderCollection.product`,
          ''
        ),
      },
    ],
    getProductDetailsComplete,
    {
      refetchOnWindowFocus: false,
      enabled: isCollection,
    }
  )
  const editOrder = useMutation((val) => updateOrder(...val), {
    onSuccess: (data) => {
      message.success('Measures updated !')
      queryClient.setQueryData(['getOrderDetails', { id: orderId }], () => data)
      closeMeasure()
    },
  })

  const measureForms = get(
    data,
    `orderProducts.${productIndex}.skuMeasurements`,
    []
  )

  const formsInf = isCollection
    ? getCollectFrom(get(productDetails, 'data.category.blocks', []))
    : formOptions[getFromKey(data.orderProducts[productIndex])]?.measuresForms

  return (
    <>
      <Tabs
        className="orders-tabs"
        activeKey={activeKey}
        defaultActiveKey="0"
        onChange={setActiveKey}
      >
        {measureForms.map((measure, index) => (
          <TabPane
            tab={`Measurement after adjustmento ${index + 1}`}
            style={{ padding: '0px 24px' }}
            key={`${index}`}
          >
            <Formik
              initialValues={data}
              onSubmit={(values) => editOrder.mutate([values, orderId])}
            >
              {({ values, errors, handleChange }) => (
                <Form>
                  <FormContent
                    values={values}
                    errors={errors}
                    path={`orderProducts.${productIndex}`}
                    handleChange={handleChange}
                    forms={formsInf.map((e) => ({
                      ...e,
                      key: e.key.replace('0', `${index}`),
                    }))}
                  />
                  {!isCreating && (
                    <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                      <Button
                        style={{ marginRight: 12 }}
                        type="primary"
                        htmlType="submit"
                      >
                        Update current measure
                      </Button>
                      <Button
                        onClick={() => {
                          setIsCreating(true)
                          setActiveKey(`${measureForms.length}`)
                        }}
                        type="primary"
                      >
                        {/* Modify measures */}
                        Add measure adjustment
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </TabPane>
        ))}
        {isCreating && (
          <TabPane
            tab={`Measurement after adjustmenti ${measureForms.length + 1}`}
            style={{ padding: '0px 24px' }}
            key={`${measureForms.length}`}
          >
            <Formik
              initialValues={data}
              // validate={(values) => {
              //   const errors = {}
              //   if (values.balance < 0) {
              //     errors.balance = 'Balance must be greater than 0'
              //   }
              //   return errors
              // }}
              onSubmit={async (values) => editOrder.mutate([values, orderId])}
            >
              {({ values, errors, handleChange }) => (
                <Form>
                  <FormContent
                    values={values}
                    errors={errors}
                    path={`orderProducts.${productIndex}`}
                    handleChange={handleChange}
                    forms={formsInf.map((e) => ({
                      ...e,
                      key: e.key.replace('0', `${measureForms.length}`),
                    }))}
                  />
                  <Space style={{ marginTop: 8 }}>
                    <Button
                      loading={editOrder.isLoading}
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                    <Button
                      disabled={editOrder.isLoading}
                      onClick={() => {
                        setIsCreating(false)
                        if (activeKey === `${measureForms.length}`) {
                          setActiveKey(`${measureForms.length - 1}`)
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Space>
                </Form>
              )}
            </Formik>
          </TabPane>
        )}
      </Tabs>
    </>
  )
}

const EditAdjustment = React.memo(({ closeMeasure, data, productIndex }) => {
  let { orderId } = useParams()
  const queryClient = useQueryClient()
  const editOrder = useMutation((val) => updateOrder(...val), {
    onSuccess: (data) => {
      message.success('Order updated !')
      queryClient.setQueryData(['getOrderDetails', { id: orderId }], () => data)
      closeMeasure()
    },
  })

  return (
    <Formik
      initialValues={data}
      onSubmit={async (values) => {
        let newValues = JSON.stringify(values)
        newValues = JSON.parse(newValues)
        let newProductTotal = 0

        for (let i = 0; i < get(values, `orderProducts`, []).length; i++) {
          if (
            get(
              values,
              `orderProducts.${i}.orderAdjustment.itemDescritpion`,
              null
            ) === 'af'
          ) {
            set(newValues, `orderProducts.${i}.orderAdjustment.price`, 0)
          } else {
            let price = 0
            const type = get(
              values,
              `orderProducts.${i}.orderAdjustment.type`,
              ''
            )
            const desc = get(
              values,
              `orderProducts.${i}.orderAdjustment.itemDescritpion`,
              ''
            )

            for (
              let j = 0;
              j <
              get(
                values,
                `orderProducts.${i}.orderAdjustment.adjustmentPositions`,
                []
              ).length;
              j++
            ) {
              const adjType = get(
                values,
                `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}.positionRef`,
                ''
              )

              let shoesCalculate = {}
              if (type === 'shoes') {
                shoesCalculate = get(
                  values,
                  `orderProducts.${i}.orderAdjustment.adjustmentPositions.${j}`,
                  {}
                )
              }

              if (desc !== 'af') {
                // Get the product price and set the price of the adjustment
                const productPrice = await getProductPrices()

                const priceData = productPrice.list.products.find(
                  (product) => product.type === type
                )
                if (priceData) {
                  let adjustmentData = {}
                  if (shoesCalculate.adjustmentType === 'color') {
                    adjustmentData = priceData.adjustments.find(
                      (adj) => adj.key === shoesCalculate.color
                    )
                  } else if (shoesCalculate.adjustmentType === 'fix') {
                    adjustmentData = priceData.adjustments.find(
                      (adj) => adj.key === shoesCalculate.fix
                    )
                  } else {
                    adjustmentData = priceData.adjustments.find(
                      (adj) => adj.type === adjType
                    )
                  }
                  if (adjustmentData) {
                    price = adjustmentData.price
                  }
                }
              }
            }

            set(newValues, `orderProducts.${i}.orderAdjustment.price`, price)
            newProductTotal = newProductTotal + price
          }
        }

        const totalSpent = get(values, 'totalSpent', 0) + newProductTotal
        const balance =
          totalSpent -
          get(values, 'deposit', 0) -
          get(values, 'deposit2', 0) -
          get(values, 'discount', 0)

        // return
        editOrder.mutate([{ ...newValues, totalSpent, balance }, orderId])
      }}
    >
      {({ values, errors, handleChange }) => (
        <Form style={{ margin: '0px 18px' }}>
          <FormContent
            values={values}
            errors={errors}
            // hideErrors={!isValidated}
            path={`orderProducts.${productIndex}`}
            handleChange={handleChange}
            forms={
              adjustmentInfos[
                get(
                  values,
                  `orderProducts.${productIndex}.orderAdjustment.type`,
                  null
                )
              ]?.forms
            }
          />
          <Space style={{ padding: '8px 24px' }}>
            <Button
              loading={editOrder.isLoading}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
            <Button disabled={editOrder.isLoading} onClick={closeMeasure}>
              Cancel
            </Button>
          </Space>
        </Form>
      )}
    </Formik>
  )
})

const EditOrderInfos = React.memo(({ closeMeasure, data, productIndex }) => {
  let { orderId } = useParams()
  const queryClient = useQueryClient()
  const [activeKey, setActiveKey] = useState('0')

  const isCollection =
    getFromKey(data.orderProducts[productIndex]) === 'collection'

  const productDetails = useQuery(
    [
      'getProductDetailsComplete',
      {
        id: get(
          data,
          `orderProducts.${productIndex}.orderCollection.product`,
          ''
        ),
      },
    ],
    getProductDetailsComplete,
    {
      refetchOnWindowFocus: false,
      enabled: isCollection,
    }
  )

  const editOrder = useMutation((val) => updateOrder(...val), {
    onSuccess: (data) => {
      message.success('Order updated !')
      queryClient.setQueryData(['getOrderDetails', { id: orderId }], () => data)
      closeMeasure()
    },
  })

  const formsInf = isCollection
    ? getCollectFrom(get(productDetails, 'data.category.blocks', []))
    : formOptions[getFromKey(data.orderProducts[productIndex])]?.measuresForms

  const formsInfF = isCollection
    ? getCollectFromForm(get(productDetails, 'data.category.blocks', []))
    : formOptions[getFromKey(data.orderProducts[productIndex])]?.forms

  return (
    <Formik
      initialValues={data}
      // validate={() => {
      //   // Why have no validation here ?
      //   // const errors = {}
      //   // if (values.balance < 0) {
      //   //   errors.balance = 'Balance must be greater than 0'
      //   // }
      //   // return errors
      // }}

      validate={(values) => {
        const errors = {}
        const innerStep = productIndex

        // innerStep
        const path = ['orderProducts', innerStep]
        const currentProduct = values.orderProducts[innerStep]

        if (!currentProduct) return

        if (currentProduct.orderProductType === 'collection') {
          // to do check collection errors
          const collectionType = get(
            currentProduct,
            'orderCollection.productType',
            []
          )

          for (const prodKey of collectionType) {
            get(formOptions, `${prodKey}.measuresForms`, []).forEach((form) =>
              // formOptions[prodKey].measuresForms.forEach((form) =>
              form.form.forEach((prodForm) =>
                prodForm.sections.forEach((section) =>
                  section.fields.forEach((field) => {
                    const name = [...path, form.key, ...prodForm.key].join('.')
                    const fieldValue = get(
                      values,
                      name + '.' + field.key.join('.'),
                      ''
                    )

                    const fieldIsRequired = field.isConditionalRequired
                      ? field.isConditionalRequired(values, name + '.')
                      : !!field.isRequired

                    if (fieldIsRequired && !fieldValue && fieldValue !== 0) {
                      set(
                        errors,
                        name + '.' + field.key.join('.'),
                        'This field is required'
                      )
                    }
                  })
                )
              )
            )
          }
        } else if (currentProduct.orderProductType === 'adjustment') {
          // formOptions[currentProduct.type].measuresForms.forEach((form) =>
          //   form.form.forEach((prodForm) =>
          //     prodForm.sections.forEach((section) =>
          //       section.fields.forEach((field) => {
          //         const fieldValue = get(
          //           values,
          //           [
          //             ...path,
          //             form.key.replace(
          //               'orderCustom.',
          //               'orderAdjustment.'
          //             ),
          //             ...prodForm.key,
          //             ...field.key,
          //           ].join('.'),
          //           ''
          //         )
          //         if (
          //           field.isRequired &&
          //           !fieldValue &&
          //           fieldValue !== 0
          //         ) {
          //           set(
          //             errors,
          //             [
          //               ...path,
          //               form.key.replace(
          //                 'orderCustom.',
          //                 'orderAdjustment.'
          //               ),
          //               ...prodForm.key,
          //               ...field.key,
          //             ].join('.'),
          //             'This field is required'
          //           )
          //         }
          //       })
          //     )
          //   )
          // )
        } else {
          // TODO: refactor this in the future, this is ugly

          /// Jacket
          // "Waist" and "Chest" should have less than 18 cm difference
          const jacketWaist = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementJacketBlazer.waist`,
            0
          )
          const jacketChest = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementJacketBlazer.chest`,
            0
          )
          if (Math.abs(jacketWaist - jacketChest) > 18) {
            set(
              errors,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementJacketBlazer.waist`,
              'Waist and chest difference should be less than 18cm'
            )
          }

          /// Vest
          // The difference between "vest length front straight"
          // and "vest length back straight" should be less than 1cm
          const vestLengthFrontStraight = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementVest.lengthFrontStraight`,
            0
          )
          const vestLengthBackStraight = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementVest.lengthBackStraight`,
            0
          )
          const vestBottomShape = get(
            currentProduct,
            'orderCustom.orderCustomVest.vestBottomShape',
            ''
          )
          if (
            vestBottomShape === 'straight_bottom' &&
            vestLengthFrontStraight &&
            vestLengthBackStraight &&
            Math.abs(vestLengthFrontStraight - vestLengthBackStraight) > 1
          ) {
            set(
              errors,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementVest.lengthFrontStraight`,
              'Front and back length difference should be less than 1cm'
            )
          }
          // The difference between "vest length front cross"
          // and "vest length back cross" should be less than 6cm
          const vestLengthFrontCross = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementVest.lengthFrontCross`,
            0
          )
          const vestLengthBackCross = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementVest.lengthBackCross`,
            0
          )
          if (
            vestBottomShape === 'cross_arrow' &&
            vestLengthFrontCross &&
            vestLengthBackCross &&
            Math.abs(vestLengthFrontCross - vestLengthBackCross) > 6
          ) {
            set(
              errors,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementVest.lengthFrontCross`,
              'Front and back length difference should be less than 6cm'
            )
          }
          // The difference between "chest" and "waist" should be larger than 12 cm
          const vestChest = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementVest.chest`,
            0
          )
          const vestWaist = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementVest.waist`,
            0
          )
          if (vestChest && vestWaist && Math.abs(vestChest - vestWaist) > 12) {
            set(
              errors,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementVest.chest`,
              'Chest and waist difference cannot be larger than 12cm'
            )
          }

          /// Shirt
          // The diff between lengthFront and lengthBack should be less than 18cm
          const shirtLengthFront = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementShirt.lengthFront`,
            0
          )
          const shirtLengthBack = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementShirt.lengthBack`,
            0
          )
          if (Math.abs(shirtLengthFront - shirtLengthBack) > 18) {
            set(
              errors,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementShirt.lengthFront`,
              'Front and back length difference should be less than 18cm'
            )
          }
          // Prefill "shirt length back" depending on "shirt length front"
          if (shirtLengthFront && !shirtLengthBack) {
            set(
              values,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementShirt.lengthBack`,
              shirtLengthFront
            )
          }
          // The diff between chest and waist should be less than 18cm
          const shirtChest = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementShirt.chest`,
            0
          )
          const shirtWaist = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementShirt.waist`,
            0
          )
          if (
            shirtChest &&
            shirtWaist &&
            Math.abs(shirtChest - shirtWaist) > 18
          ) {
            set(
              errors,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementShirt.chest`,
              'Chest and waist difference should be less than 18cm'
            )
          }
          //  Prefill the "Shirt sleeve wrist (left)"
          //  depending on the field "Shirt sleeve wrist (right)" -> Can be modified
          const shirtSleeveWristRight = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementShirt.sleeveWristRight`,
            0
          )
          const shirtSleeveWristLeft = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementShirt.sleeveWristLeft`,
            0
          )
          if (shirtSleeveWristLeft && !shirtSleeveWristRight) {
            set(
              values,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementShirt.sleeveWristRight`,
              shirtSleeveWristLeft
            )
          }

          /// Jeans
          // The sum of "front crotch" and "back crotch" need to be equal with "whole crotch"
          const jeansFrontCrotch = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementJean.frontCrotch`,
            0
          )
          const jeansBackCrotch = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementJean.backCrotch`,
            0
          )
          const jeansWholeCrotch = get(
            currentProduct,
            `skuMeasurements.0.skuMeasurementJean.crotch`,
            0
          )
          if (
            jeansFrontCrotch &&
            jeansBackCrotch &&
            jeansWholeCrotch &&
            jeansFrontCrotch + jeansBackCrotch !== jeansWholeCrotch
          ) {
            set(
              errors,
              `orderProducts.${innerStep}.skuMeasurements.0.skuMeasurementJean.crotch`,
              'The sum of "front crotch" and "back crotch" need to be equal with "whole crotch"'
            )
          }

          get(
            formOptions,
            `${getFromKey(currentProduct)}.measuresForms`,
            []
          ).forEach(
            // formOptions[getFromKey(currentProduct)].measuresForms.forEach(
            (form) =>
              form.form.forEach((prodForm) =>
                prodForm.sections.forEach((section) =>
                  section.fields.forEach((field) => {
                    const name = [...path, form.key, ...prodForm.key].join('.')

                    const fieldValue = get(
                      values,
                      name + '.' + field.key.join('.'),
                      ''
                    )

                    const fieldIsRequired = field.isConditionalRequired
                      ? field.isConditionalRequired(values, name + '.')
                      : !!field.isRequired

                    if (fieldIsRequired && !fieldValue && fieldValue !== 0) {
                      set(
                        errors,
                        name + '.' + field.key.join('.'),
                        'This field is required'
                      )
                    }
                  })
                )
              )
          )
        }

        return errors
      }}
      onSubmit={async (values) => {
        let newValues = JSON.stringify(values)
        newValues = JSON.parse(newValues)
        let newProductTotal = 0

        get(newValues, `orderProducts`, []).forEach((prod, idx) => {
          let qty = 1
          let price = 0

          if (prod.orderProductType === 'custom') {
            for (var key of Object.keys(prod.orderCustom)) {
              qty += get(prod, `orderCustom.${key}.addMore`, 0)
            }
            price = prod.orderCustom.price
          } else if (prod.orderProductType === 'collection') {
            price = prod.orderCollection.price

            if (idx === productIndex && prod.orderCollection.options) {
              prod.orderCollection.options.forEach((option, jdx) => {
                option.subOptions.forEach((subOption, kdx) => {
                  set(
                    newValues,
                    `orderProducts.${idx}.orderCollection.options.${jdx}.subOptions.${kdx}.selected`,
                    prod.orderCollection.optionsTemp?.includes(subOption._id)
                  )
                })
              })
            }
          }

          // extra shirts
          let extraShirtsPrice = 0
          let extraShirts = get(
            prod,
            `orderCustom.orderCustomShirt.extraShirts`,
            []
          )
          if (extraShirts) {
            extraShirts.forEach((item) => {
              extraShirtsPrice += get(item, 'priceNum', 0)
            })
          }

          newProductTotal = newProductTotal + price * qty + extraShirtsPrice
        })

        const totalSpent = newProductTotal
        const balance =
          newProductTotal -
          get(values, 'deposit', 0) -
          get(values, 'deposit2', 0) -
          get(values, 'discount', 0)

        // return
        editOrder.mutate([{ ...newValues, totalSpent, balance }, orderId])
      }}
    >
      {({ values, errors, handleChange }) => (
        <Form>
          <Tabs
            className="orders-tabs"
            activeKey={activeKey}
            defaultActiveKey="0"
            onChange={setActiveKey}
          >
            <TabPane tab={'Options'} style={{ padding: '0px 24px' }} key={'0'}>
              {get(
                values,
                `orderProducts.${productIndex}.orderProductType`,
                false
              ) === 'collection' ? (
                <CollectionForm
                  values={values}
                  errors={{}}
                  path={`orderProducts.${productIndex}`}
                  handleChange={(e) => handleChange(e)}
                />
              ) : (
                <>
                  {/* */}
                  <StyleForm layout="vertical" style={{ width: '100%' }}>
                    <Card title={'Price'}>
                      <Space
                        size={0}
                        direction="vertical"
                        style={{ width: '100%' }}
                      >
                        {formOptions[
                          getFromKey(values.orderProducts[productIndex])
                        ].priceList ? (
                          <StyleForm.Item>
                            <Select
                              // name={`orderProducts.${index}.orderCustom.quality`}
                              style={{ width: '50%' }}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: `orderProducts.${productIndex}.orderCustom.quality`,
                                    value: e,
                                  },
                                })

                                handleChange({
                                  target: {
                                    name: `orderProducts.${productIndex}.orderCustom.price`,
                                    value:
                                      formOptions[
                                        getFromKey(
                                          values.orderProducts[productIndex]
                                        )
                                      ].priceList[e].price,
                                  },
                                })
                              }}
                              value={get(
                                values,
                                `orderProducts.${productIndex}.orderCustom.quality`,
                                ''
                              )}
                            >
                              {Object.keys(
                                formOptions[
                                  getFromKey(values.orderProducts[productIndex])
                                ].priceList
                              ).map((key) => (
                                <Select.Option key={key} value={key}>
                                  {
                                    formOptions[
                                      getFromKey(
                                        values.orderProducts[productIndex]
                                      )
                                    ].priceList[key].label
                                  }
                                </Select.Option>
                              ))}
                            </Select>
                          </StyleForm.Item>
                        ) : null}
                        <StyleForm.Item
                          validateStatus={
                            get(
                              errors,
                              `orderProducts.${productIndex}.orderCustom.price`,
                              false
                            )
                              ? 'error'
                              : null
                          }
                          help={get(
                            errors,
                            `orderProducts.${productIndex}.orderCustom.price`,
                            null
                          )}
                        >
                          <Input
                            name={`orderProducts.${productIndex}.orderCustom.price`}
                            type="text"
                            placeholder={'-'}
                            style={{ width: '50%' }}
                            onChange={handleChange}
                            value={get(
                              values,
                              `orderProducts.${productIndex}.orderCustom.price`,
                              ''
                            )}
                            disabled={
                              !formOptions[
                                getFromKey(values.orderProducts[productIndex])
                              ]?.isPriceEditable
                            }
                          />
                        </StyleForm.Item>
                      </Space>
                    </Card>
                  </StyleForm>
                  <FormContent
                    isEdition={true}
                    values={values}
                    errors={{}}
                    path={`orderProducts.${productIndex}`}
                    handleChange={handleChange}
                    forms={formsInfF}
                  />
                  <StyleForm layout="vertical" style={{ width: '100%' }}>
                    <Card title={'Persona Type'}>
                      <Space
                        size={0}
                        direction="vertical"
                        style={{ width: '100%' }}
                      ></Space>
                      <Button
                        type={
                          get(
                            values,
                            `orderProducts.${productIndex}.orderCustom.personaeType`,
                            null
                          ) === 'special_event'
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={() =>
                          handleChange({
                            target: {
                              name: `orderProducts.${productIndex}.orderCustom.personaeType`,
                              value: 'special_event',
                            },
                          })
                        }
                      >
                        Special Event
                      </Button>
                      <Button
                        type={
                          get(
                            values,
                            `orderProducts.${productIndex}.orderCustom.personaeType`,
                            null
                          ) === 'wardrobe_update'
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={() =>
                          handleChange({
                            target: {
                              name: `orderProducts.${productIndex}.orderCustom.personaeType`,
                              value: 'wardrobe_update',
                            },
                          })
                        }
                      >
                        Wardrobe Update
                      </Button>
                    </Card>
                  </StyleForm>
                </>
              )}
            </TabPane>
            <TabPane
              tab={'Measures'}
              disabled={!formsInf}
              style={{ padding: '0px 24px' }}
              key={'1'}
            >
              <FormContent
                values={values}
                errors={errors}
                path={`orderProducts.${productIndex}`}
                handleChange={handleChange}
                forms={formsInf}
              />
            </TabPane>
          </Tabs>
          <Space style={{ padding: '8px 24px' }}>
            <Button
              loading={editOrder.isLoading}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
            <Button disabled={editOrder.isLoading} onClick={closeMeasure}>
              Cancel
            </Button>
          </Space>
        </Form>
      )}
    </Formik>
  )
})

const CollectionForm = ({ values, path, handleChange }) => {
  // const [savedOption, setSavedOption] = useState([])
  const productName = `${path}.orderCollection.product`
  const productPrice = `${path}.orderCollection.price`
  const productTempOptions = `${path}.orderCollection.optionsTemp`

  const savedOption = get(values, `${path}.orderCollection.options`, [])

  const product = useQuery(
    [
      'getProductDetailsComplete',
      {
        id: get(values, productName, ''),
      },
    ],
    getProductDetailsComplete,
    {
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    // const opts = get(values, `${path}.orderCollection.options`, [])
    handleChange({
      target: {
        name: productTempOptions,
        value: savedOption?.map(
          (option) => option?.subOptions?.find((e) => e.selected)?._id || null
        ),
      },
    })
    // setSavedOption(opts)
  }, [])

  return (
    <StyleForm layout="vertical" style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card title="Choose product">
          <Space size={0} direction="vertical" style={{ width: '100%' }}>
            <StyleForm.Item label="Product name">
              <Select
                value={product.data.id}
                disabled={true}
                style={{ width: '50%' }}
              >
                <Select.Option value={product.data.id}>
                  {product?.data?.name?.en || product?.data?.name?.zh}
                </Select.Option>
              </Select>
            </StyleForm.Item>
            {product && (
              <StyleForm.Item label="Price">
                <InputNumber
                  min={0}
                  name={productPrice}
                  style={{ width: '50%' }}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: productPrice,
                        value: e,
                      },
                    })
                  }
                  value={get(values, productPrice, null)}
                  // disabled={field.isInactive}
                />
              </StyleForm.Item>
            )}
          </Space>
        </Card>
        {savedOption && !!savedOption?.length && (
          <Card title="Options">
            <Space size={0} direction="vertical" style={{ width: '100%' }}>
              {savedOption?.map((option, index) => {
                // const err = get(errors, `${productTempOptions}.${index}`, null)

                return (
                  <StyleForm.Item
                    key={index}
                    label={option?.name?.en || option?.name?.zh}
                    // validateStatus={err && !hideErrors ? 'error' : null}
                    // help={hideErrors ? null : err}
                  >
                    <Select
                      // disabled={true}
                      value={get(values, `${productTempOptions}.${index}`, '')}
                      style={{ width: '50%' }}
                      onChange={(id) => {
                        handleChange({
                          target: {
                            name: `${productTempOptions}.${index}`,
                            value: id,
                          },
                        })
                      }}
                    >
                      {option.subOptions?.map((subOption) => (
                        <Select.Option value={subOption._id}>
                          {subOption.name?.en || subOption.name?.zh}
                        </Select.Option>
                      ))}
                    </Select>
                  </StyleForm.Item>
                )
              })}
            </Space>
          </Card>
        )}
      </Space>
    </StyleForm>
  )
}

const calculateTotal = (values) => {
  return values.orderProducts.reduce((acc, prod) => {
    let price = 0

    if (prod.orderProductType === 'collection') {
      price = get(prod, 'orderCollection.price', 0)
    } else if (prod.orderProductType === 'adjustment') {
      price = get(prod, 'orderAdjustment.price', 0)
    } else {
      price = get(prod, 'orderCustom.price', 0)
      // extra shirts prices
      const extraShirts = get(
        prod,
        'orderCustom.orderCustomShirt.extraShirts',
        []
      )
      if (extraShirts && Array.isArray(extraShirts)) {
        extraShirts.forEach((item) => {
          price += get(item, 'priceNum', 0)
        })
      }
    }

    return acc + price
  }, 0)
}

const calculateBalance = (values) => {
  const total = calculateTotal(values)
  const discount = values.discount || 0
  const deposit1 = values.deposit || 0
  const deposit2 = values.deposit2 || 0
  return total - discount - deposit1 - deposit2
}

export default EditOrder
