import get from 'lodash.get'

export const StoresDescriptionForm = {
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Store Name',
          key: ['name', 'en'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Store Name (中文)',
          key: ['name', 'zh'],
          isRequired: false,
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'StoreID',
          key: ['storeID'],
          isRequired: true,
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'City',
          key: ['city'],
          isRequired: true,
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Company Address',
          isRequired: true,
          key: ['address'],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
        {
          label: 'Country',
          isRequired: true,
          key: ['country'],
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
        {
          label: 'Bank Information',
          isRequired: true,
          key: ['vat'],
          pos: {
            col: 1,
            row: 9,
            size: 1,
          },
        },
        {
          label: 'Vendor Signature',
          isRequired: true,
          key: ['vendor'],
          pos: {
            col: 1,
            row: 10,
            size: 1,
          },
        },
        {
          label: 'Email',
          isRequired: true,
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input the distributor E-mail!',
            },
          ],
          key: ['email'],
          isHiding: (values, name) =>
            get(values, `${name}franchise`, '') === true,
          pos: {
            col: 1,
            row: 11,
            size: 1,
          },
        },
        {
          label: 'Currency',
          type: 'select',
          key: ['currency'],
          options: [
            { key: 'cny', label: 'CNY - ¥' },
            { key: 'usd', label: 'USD - $' },
            { key: 'eur', label: 'EUR - €' },
            { key: 'gbp', label: 'GBP - £' },
            { key: 'jpy', label: 'JPY - ¥' },
            { key: 'aud', label: 'AUD - $' },
            { key: 'cad', label: 'CAD - $' },
            { key: 'chf', label: 'CHF - ₣' },
            { key: 'hkd', label: 'HKD - $' },
            { key: 'nzd', label: 'NZD - $' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            row: 12,
            size: 1,
          },
        },
        {
          label: 'Distributor ',
          type: 'switch',
          key: ['franchise'],
          isRequired: false,
          initialValues: false,
          defaultChecked: false,
          pos: {
            col: 1,
            row: 13,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const StoresDescriptionFranchise = {
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Store Name',
          key: ['name', 'en'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'City',
          key: ['city'],
          isRequired: true,
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Company Address',
          isRequired: true,
          key: ['address'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Country',
          isRequired: true,
          key: ['country'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Bank Information',
          isRequired: true,
          key: ['vat'],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Vendor Signature',
          isRequired: true,
          key: ['vendor'],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Email',
          isRequired: true,
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input the distributor E-mail!',
            },
          ],
          key: ['email'],
          isHiding: (values, name) =>
            get(values, `${name}franchise`, '') === true,
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const StoresMediaForm = {
  title: 'Logo',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          type: 'upload',
          label: '109*109px (1:1 ratio)',
          isRequired: false, // false only for test
          requestURI: '/files/store-logo',
          storageURI: '/storage/store-logo',
          key: ['logo'],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const StoresFaviconForm = {
  title: 'Favicon',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          type: 'upload',
          label: '32*32px (1:1 ratio)',
          isRequired: false,
          requestURI: '/files/favicon',
          storageURI: '/storage/favicon',
          key: ['favicon'],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const StoresFranchises = {
  title: 'Distributor',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Company Address',
          isRequired: true,
          key: ['address'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Country',
          isRequired: true,
          key: ['country'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Bank Information',
          isRequired: true,
          key: ['vat'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Vendor Signature',
          isRequired: true,
          key: ['vendor'],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Currency',
          type: 'select',
          key: ['currency'],
          options: [
            { key: 'cny', label: 'CNY - ¥' },
            { key: 'usd', label: 'USD - $' },
            { key: 'eur', label: 'EUR - €' },
            { key: 'gbp', label: 'GBP - £' },
            { key: 'jpy', label: 'JPY - ¥' },
            { key: 'aud', label: 'AUD - $' },
            { key: 'cad', label: 'CAD - $' },
            { key: 'chf', label: 'CHF - ₣' },
            { key: 'hkd', label: 'HKD - $' },
            { key: 'nzd', label: 'NZD - $' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Email',
          isRequired: true,
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input the franchise E-mail!',
            },
          ],
          key: ['email'],
          isHiding: (values, name) =>
            get(values, `${name}franchise`, '') === true,
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
      ],
    },
  ],
}
