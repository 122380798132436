import { useMemo } from 'react'
import { Button, Card, Image, PageHeader, Table, Tabs } from 'antd'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import moment from 'moment'
import React, { useState } from 'react'

import {
  ClientsBodyMeasurementForm,
  ClientsBodyMeasurementFormExt,
  ClientsComplexionForm,
  ClientsDetailsForm,
} from '../../forms/Clients'
import { get3Dlook, getClientDetails } from '../../network/Clients'
import Loader from '../../components/Loader'
import Error from '../../components/Error'
import get from 'lodash.get'

import { getOrders, updateOrder } from '../../network/Orders'
import Status from '../../components/tables/cells/Status'
import TableBlock from '../../components/tables/TableBlock'

import { threeDParsing } from '../../utils/utils'
import { useAuth } from '../../hooks/useAuth'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { getCurrencySymbol } from '../../utils/currencyUtils'

const { TabPane } = Tabs

const EditClient = ({ history }) => {
  let { clientId } = useParams()
  const [totalDueCurrency, setTotalDueCurrency] = useState('')
  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  const clientDetails = useQuery(
    [
      'getClientDetails',
      {
        id: clientId,
      },
    ],
    getClientDetails,
    {
      // onSuccess: (data) => {
      //   data.birthDate = moment(data.birthDate)
      //   form.setFieldsValue(data)
      // },
      refetchOnWindowFocus: false,
    }
  )

  const client3DLDetails = useQuery(
    [
      '3DLooks',
      {
        id: clientId,
      },
    ],
    () => get3Dlook(clientId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )

  const tddata = useMemo(() => {
    if (!client3DLDetails.data) return {}

    return threeDParsing(client3DLDetails.data)
  }, [client3DLDetails.data])

  const filters = (history) => ({
    fields: [
      {
        type: 'text',
        label: 'Order ID',
        key: 'orderID',
      },
      {
        type: 'daterange',
        label: 'Dates',
        key: ['dateStart', 'dateEnd'],
      },
      {
        type: 'select',
        label: 'Channel',
        key: 'channel',
        options: [
          { label: 'WeChat MP', key: 'mp' },
          { label: 'Store', key: 'store' },
        ],
      },
      {
        type: 'select',
        label: 'Status',
        key: 'status',
        options: [
          { label: 'In preparation', key: 'in_preparation' },
          { label: 'In production', key: 'in_production' },
          { label: 'Producing', key: 'producing' },
          { label: 'Cancelled', key: 'cancelled' },
          { label: 'Ready to pick up', key: 'ready_to_pick_up' },
          { label: 'On the way (Client)', key: 'on_the_way_client' },
          { label: 'On the way (Store)', key: 'on_the_way_store' },
          { label: 'Order finished', key: 'order_finished' },
        ],
      },
    ],
    actions: [
      {
        label: '+ New order',
        isActive: permissions.includes('new_orders'),
        action: () => history.push('/orders/create'),
      },
    ],
  })

  const columns = (history, keyRequest) => [
    {
      title: 'Order ID',
      key: 'orderID',
      render: (e) => <div className={e.status}>{e.orderID}</div>,
    },
    {
      title: 'Customer name',
      key: 'customer.name',
      render: (e) => (
        <div
          className={e.status}
        >{`${e.customer?.firstName} ${e.customer?.lastName}`}</div>
      ),
      sorter: true,
    },
    {
      title: 'Date',
      key: 'date',
      render: (e) => (
        <div className={e.status}>{moment(e.date).format('YYYY-MM-DD')}</div>
      ),
      sorter: true,
    },
    {
      title: 'Status',
      key: 'status',
      width: 200,
      render: (e) => (
        <div className={e.status}>
          <Status
            status={e.status}
            fieldKey={'status'}
            requestName={'getOrders'}
            keyRequest={keyRequest}
            editRequest={updateOrder}
            noPoint
            id={e.id}
            config={[
              { label: 'In preparation', key: 'in_preparation' },
              { label: 'In production', key: 'in_production' },
              { label: 'Producing', key: 'producing' },
              { label: 'Cancelled', key: 'cancelled' },
              { label: 'Ready to pick up', key: 'ready_to_pick_up' },
              { label: 'On the way (Client)', key: 'on_the_way_client' },
              { label: 'On the way (Store)', key: 'on_the_way_store' },
              { label: 'Order finished', key: 'order_finished' },
            ]}
          />
        </div>
      ),
      sorter: true,
    },
    {
      title: 'Paid',
      key: 'paymentStatus',
      width: 175,
      render: (e) => (
        <div className={e.status}>
          <Status
            status={e.paymentStatus}
            fieldKey={'paymentStatus'}
            requestName={'getOrders'}
            keyRequest={keyRequest}
            editRequest={updateOrder}
            id={e.id}
            config={[
              { label: 'Unpaid', key: 'unpaid', color: 'red' },
              { label: 'Deposit', key: 'deposit', color: 'grey' },
              { label: 'Paid', key: 'paid', color: 'green' },
            ]}
          />

          {/* {e.paymentStatus} */}
        </div>
      ),
      sorter: true,
    },
    {
      title: 'Deposit',
      key: 'deposit',
      render: (e) => (
        <div className={e.status}>{`${e.deposit || 0} + ${
          e.deposit2 || 0
        }`}</div>
      ),
      sorter: true,
    },
    {
      title: 'Balance',
      key: 'balance',
      render: (e) => <div className={e.status}>{e.balance || 0}</div>,
      sorter: true,
    },
    {
      title: `Total due (${totalDueCurrency})`,
      key: 'totalSpent',
      render: (e) => {
        let symbol = ''
        if (user.franchise === false) {
          symbol = getCurrencySymbol(e.currency)
        }

        if (user.franchiseStore) {
          const currencyTotalDue = {
            cny: 'RMB',
            eur: 'EUR',
            usd: 'USD',
            gbp: 'GBP',
            jpy: 'JPY',
            aud: 'AUD',
            cad: 'CAD',
            chf: 'CHF',
            hkd: 'HKD',
            nzd: 'NZD',
          }
          setTotalDueCurrency(currencyTotalDue[e.currency] || '')
        }

        return (
          <div className={e.status}>
            {`${(e.totalSpent - (e.discount?.toFixed(2) || 0)).toFixed(
              2
            )} ${symbol}`}
          </div>
        )
      },
      sorter: true,
    },

    {
      title: 'Channel',
      key: 'channel',
      render: (e) => {
        const values = {
          store: 'Store',
          wechat_miniprogam: 'MP',
        }

        return <div className={e.status}>{values[e.channel]}</div>
      },
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      width: 25,
      render: (e) => {
        return (
          <div className={e.status}>
            <Button
              disabled={!permissions.includes('new_orders')}
              onClick={() => {
                history.push(`./../../orders/${e.id}`)
              }}
              type="primary"
            >
              Edit
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Customer informations'}
        onBack={() => history.push('/clients')}
        style={{ backgroundColor: '#fff' }}
      />

      <Tabs
        defaultActiveKey={history?.location?.toOrder ? '2' : '1'}
        className="user-detail-tabs"
      >
        <TabPane tab="Infos" key="1">
          {clientDetails.isError ? (
            <Error retry={() => clientDetails.refetch()} />
          ) : clientDetails.isLoading ? (
            <Loader />
          ) : (
            <>
              <div
                className="section-from-vertical"
                style={{ width: '100%', padding: '12px 12px 0px 12px' }}
              >
                <div className="section-from-horizontal">
                  <Card
                    size="small"
                    title={ClientsDetailsForm.title}
                    style={{ flex: 1 }}
                  >
                    {ClientsDetailsForm.sections.map((section) =>
                      section.fields.map((field) => (
                        <p>
                          <strong>{field.label}: </strong>
                          {get(
                            clientDetails,
                            `data.${field.key.join('.')}`,
                            ''
                          )}
                        </p>
                      ))
                    )}
                    <p>
                      <strong>Nationality: </strong>
                      {get(clientDetails, `data.nationality`) === 'foreigner'
                        ? get(clientDetails, `data.country`)
                        : ''}
                    </p>
                    <p>
                      <strong>City of residence: </strong>
                      {get(clientDetails, `data.city`)}
                    </p>

                    <p>
                      <strong>Birth date: </strong>
                      {moment(
                        get(clientDetails, `data.birthDate`),
                        null
                      ).format('YYYY MMMM DD')}
                      {' ('}
                      {moment().diff(
                        moment(get(clientDetails, `data.birthDate`), null),
                        'years',
                        false
                      )}
                      {' years old)'}
                    </p>
                  </Card>
                  <Card
                    size="small"
                    title={ClientsComplexionForm.title}
                    style={{ flex: 1 }}
                  >
                    {ClientsComplexionForm.sections.map((section) =>
                      section.fields.map((field) => (
                        <p>
                          <strong>{field.label}: </strong>
                          {get(
                            clientDetails,
                            `data.complexion.${field.key.join('.')}`,
                            ''
                          )}
                        </p>
                      ))
                    )}
                  </Card>
                  <Card
                    size="small"
                    title={ClientsBodyMeasurementForm.title}
                    style={{ flex: 1 }}
                  >
                    {ClientsBodyMeasurementFormExt.sections.map((section) =>
                      section.fields.map((field) => (
                        <p>
                          <strong>{field.label}: </strong>
                          {get(
                            clientDetails,
                            `data.bodyMeasurements.${field.key.join('.')}`,
                            ''
                          )}
                        </p>
                      ))
                    )}

                    <p>
                      <strong>Measurements source: </strong>
                      {get(
                        clientDetails,
                        `data.bodyMeasurements.measurementSource`,
                        ''
                      ) === 'af'
                        ? 'Own measurements'
                        : '3D Scan'}
                    </p>

                    <Table
                      size="small"
                      pagination={false}
                      dataSource={ClientsBodyMeasurementForm.sections
                        .map((section) =>
                          section.fields
                            .filter(
                              (e) =>
                                e.key.join('') !== 'measurementSource' &&
                                e.type !== 'title'
                            )
                            .map((field) => ({
                              key: `data.bodyMeasurements.${field.key.join(
                                '.'
                              )}`,
                              label: field.label,
                              af: get(
                                clientDetails,
                                `data.bodyMeasurements.${field.key.join('.')}`,
                                ''
                              ),
                              '3dlook': get(tddata, field.key.join('.'), ''),
                            }))
                        )
                        .flat()}
                      // dataSource={[
                      //   {
                      //     key: '1',
                      //     name: 'Mike',
                      //     age: 32,
                      //     address: '10 Downing Street',
                      //   },
                      //   {
                      //     key: '2',
                      //     name: 'John',
                      //     age: 42,
                      //     address: '10 Downing Street',
                      //   },
                      // ]}
                      columns={[
                        { key: '', dataIndex: 'label' },
                        { title: 'Measure', dataIndex: 'af', key: 'af' },
                        {
                          title: '3D Scan',
                          dataIndex: '3dlook',
                          key: '3dlook',
                        },
                      ]}
                    />

                    {/* {ClientsBodyMeasurementForm.sections.map((section) =>
                      section.fields.map((field) => (
                        <p>
                          <strong>{field.label}: </strong>
                          {get(
                            clientDetails,
                            `data.bodyMeasurements.${field.key.join('.')}`,
                            ''
                          )}
                        </p>
                      ))
                    )} */}
                  </Card>
                </div>
                <div className="section-from-horizontal">
                  <Card
                    size="small"
                    title={'Body pictures'}
                    style={{ flex: 1 }}
                  >
                    <div className="grid-images-view">
                      {get(clientDetails, 'data.bodyPictures', []).map(
                        (pic) => (
                          <Image
                            // width={125}
                            height={125}
                            src={`${process.env.REACT_APP_API_URL}/storage/customer-body/${pic.uri}`}
                          />
                        )
                      )}
                    </div>
                  </Card>
                  <Card size="small" title={'Foot in cm'} style={{ flex: 1 }}>
                    <p>
                      <strong>Foot size: </strong>
                      {get(clientDetails, 'data.foot.footMeasure', '')}
                    </p>
                    <p>
                      <strong>Right foot: </strong>
                    </p>
                    <div className="grid-images-view">
                      {get(clientDetails, 'data.foot.picturesRight', []).map(
                        (pic) => (
                          // <div
                          //   className="grid-image-item"
                          //   style={{
                          //     backgroundImage: `url(${`${process.env.REACT_APP_API_URL}/storage/customer-foot/${pic.uri}`})`,
                          //   }}
                          // />
                          <Image
                            // width={125}
                            height={125}
                            src={`${process.env.REACT_APP_API_URL}/storage/customer-foot/${pic.uri}`}
                          />
                        )
                      )}
                    </div>
                    <p>
                      <strong>Left foot: </strong>
                    </p>
                    <div className="grid-images-view">
                      {get(clientDetails, 'data.foot.picturesLeft', []).map(
                        (pic) => (
                          // <div
                          //   className="grid-image-item"
                          //   style={{
                          //     backgroundImage: `url(${`${process.env.REACT_APP_API_URL}/storage/customer-foot/${pic.uri}`})`,
                          //   }}
                          // />
                          <Image
                            // width={125}
                            height={125}
                            src={`${process.env.REACT_APP_API_URL}/storage/customer-foot/${pic.uri}`}
                          />
                        )
                      )}
                    </div>
                  </Card>
                </div>
                <div className="section-from-horizontal">
                  {get(clientDetails, `data.addresses`, []).length ? (
                    <Card
                      size="small"
                      title={'Addresses'}
                      style={{ width: '50%' }}
                    >
                      {get(clientDetails, `data.addresses`, []).map(
                        (add, i) => (
                          <>
                            <p>
                              <strong>{`Adress ${i + 1}:`}</strong>
                            </p>
                            <p>
                              <strong>Name: </strong>
                              {get(add, 'name', '')}
                            </p>
                            <p>
                              <strong>Area: </strong>
                              {get(add, 'area', '')}
                            </p>
                            <p>
                              <strong>City: </strong>
                              {get(add, 'city', '')}
                            </p>
                            <p>
                              <strong>Zip code: </strong>
                              {get(add, 'zipCode', '')}
                            </p>
                            <p>
                              <strong>Detailled address: </strong>
                              {get(add, 'address', '')}
                            </p>
                            <p>
                              <strong>Contact person: </strong>
                              {get(add, 'contactPerson', '')}
                            </p>
                            <p>
                              <strong>Phone: </strong>
                              {get(add, 'phone', '')}
                            </p>
                            <p>
                              <strong>Comment: </strong>
                              {get(add, 'comment', '')}
                            </p>
                          </>
                        )
                      )}
                    </Card>
                  ) : null}
                  {get(clientDetails, `data.productSize`, []).length ? (
                    <Card
                      size="small"
                      title={'Product Size'}
                      style={{ width: '50%' }}
                    >
                      {get(clientDetails, `data.productSize`, []).map(
                        (add, i) => (
                          <>
                            <p>
                              <u>{`Product ${i + 1}:`}</u>
                            </p>
                            <div
                              style={{ display: 'flex', marginBottom: '15px' }}
                            >
                              <p>
                                <strong>Name: </strong>
                                {get(add, 'detail.name.en', '')}
                              </p>
                              <p style={{ marginLeft: '15px' }}>
                                <strong>Size: </strong>
                                {get(add, 'size', '')}
                              </p>
                            </div>
                          </>
                        )
                      )}
                    </Card>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </TabPane>
        <TabPane tab="Orders" key="2">
          <div style={{ height: 24, background: '#fff' }} />
          <TableBlock
            className={'order-table'}
            columns={columns}
            filters={filters}
            name={'getOrders'}
            request={getOrders}
            otherFilters={{ customer: clientId }}
            // infinite
          />
        </TabPane>
      </Tabs>
    </>
  )
}

export default EditClient
